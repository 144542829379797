<template>
    <div class="cursor-pointer min-h-[32px] leading-4 flex items-center" @click="onCopyClicked">
        <template v-if="isCheckMarkDisplayed">
            <slot v-if="checkmarkIconSlotPresent" name="checkmark-icon"></slot>
            <CheckmarkIcon v-else class="text-green-dark "/>
        </template>
        <template v-else>
            <slot v-if="copyIconSlotPresent" name="copy-icon"></slot>
            <CopyIcon v-else class="text-green-dark "/>
        </template>
    </div>
</template>
<script lang="ts" setup>
    import CopyIcon from '@/assets/icons/CopyIcon.vue'
    import CheckmarkIcon from '@/assets/icons/CheckmarkIcon.vue'
    import {computed, ref, type Ref, useSlots} from 'vue'
    import {toast} from 'vue3-toastify'
    import {useI18n} from 'vue-i18n'
    const isCheckMarkDisplayed : Ref<boolean> = ref(false)
    const t = useI18n().t
    const props = defineProps({
        text: {
            type: String,
            required: true
        }
    })
    const slots = useSlots()

    const onCopyClicked = async() => {
        if (isCheckMarkDisplayed.value) return
        isCheckMarkDisplayed.value = true

        if (!navigator.clipboard) {
            toast.error(t('errors.copy_error'))
            return
        }
        await navigator.clipboard.writeText(props.text)

        setTimeout(() => {
            isCheckMarkDisplayed.value = false
        }, 1000)
    }

    const copyIconSlotPresent = computed(() => {
        return !!slots['copy-icon']
    })
    const checkmarkIconSlotPresent = computed(() => {
        return !!slots['checkmark-icon']
    })
</script>
