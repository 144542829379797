<template>
    <Card background="transparent-light-green" border="transparent">
        <div class="flex flex-col gap-2 items-center justify-center text-center p-4">
            <a href="https://market.marinemoguls.com" target="_blank" class="w-full flex justify-center items-center" @click="onMarketLinkClicked">
                <img src="@/assets/images/mm_logo_full.svg" class="max-w-[200px] lg:max-w-[170px] w-full" alt="Marine Moguls NFTs Marketplace"/>
            </a>
            <span class="font-semibold text-4xl pt-4">{{$t('marketplace.title_1')}}<br><span class="font-black">{{$t('marketplace.title_2')}}</span></span>
            <div class="mt-4">
                <a href="https://market.marinemoguls.com" target="_blank" @click="onMarketLinkClicked">
                    <Button>{{$t('marketplace.enter_marketplace')}}</Button>
                </a>
            </div>
        </div>
    </Card>
</template>
<script lang="ts" setup>
    import Card from '@/components/Card.vue'
    import Button from '@/components/Button.vue'

    const onMarketLinkClicked = () => {
        try {
            (window as any)._paq.push(['trackEvent', 'Market Visited'])
        } catch (e) {}
    }
</script>
