import {createRouter, createWebHistory} from 'vue-router'
import Claim from '@/views/Home.vue'
import {useAppStore} from '@/stores/app'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior() {
        return { top: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: Claim
        },
        {
            path: '/claimed',
            name: 'claimed',
            component: () => import('@/views/Claimed.vue')
        },
        {
            path: '/craft/:mogul_id',
            name: 'craft',
            component: () => import('@/views/Craft.vue')
        },
        {
            path: '/prize/:mogul_id',
            name: 'prize',
            component: () => import('@/views/Prize.vue')
        },
        {
            path: '/merge/:mogul_id',
            name: 'merge',
            component: () => import('@/views/Merge/Merge.vue')
        },
        {
            path: '/send/:mogul_id',
            name: 'send',
            component: () => import('@/views/Send.vue')
        },
        {
            path: '/no-nft',
            name: 'no-nft',
            component: () => import('@/views/NoMETFINFT.vue')
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login.vue')
        },
        {
            path: '/not-found',
            name: 'not-found',
            component: () => import('@/views/NotFound.vue'),
            meta: {
                layout: 'Blank'
            }
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: '/not-found'
        }
    ]
})
router.beforeEach((to) => {
    if (to.name !== 'login') {
        const appStore = useAppStore()
        if (!appStore.isLoggedIn()) return {name: 'login'}

        if (to.name !== 'no-nft' && appStore.getWalletNFTs().length === 0 && appStore.getMarineMoguls().length === 0) {
            return {
                name: 'no-nft'
            }
        }
    }
})

export default router
