import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import type {ParsedJWT, User, UserData} from '@/types/Login'
import { useChainStore } from '@/stores/chain'
import {type Router, useRouter} from 'vue-router'
import type {NFT} from '@/types/Account'
import axios from '@/libs/axios'
import type {AxiosResponse} from 'axios'
import type {Mogul, MogulBalance} from '@/types/Mogul'
import {captureException} from '@sentry/vue'
import {getJwt, isJWTExpired, parseJwt} from '@/libs/auth'
import type {CurrencyToken} from '@/types/Currency'
import type {TreasuryClaimData} from '@/types/TreasuryClaims'

export const useAppStore = defineStore('app', () => {
    const chainStore = useChainStore()
    const windowWidth: Ref<number> = ref(0)
    const loaderActive: Ref<number> = ref(0)
    const userData: Ref<User> = ref({} as User)
    const router: Ref<Router | undefined> = ref(undefined)
    const loggedInUser: Ref<UserData> = ref({
        WalletAddress: '',
        IsLoggedIn: false,
        JWT: ''
    })
    const walletNFTs : Ref<Array<NFT>> = ref([])
    const selectedNFT : Ref<NFT|undefined> = ref(undefined)
    const preloadedImages : Array<HTMLImageElement> = []
    const treasuryClaimData : Ref<TreasuryClaimData|undefined> = ref(undefined)

    // Mogul NFTs
    const MogulNFTs : Ref<any> = ref([])
    const MogulBalance : Ref<MogulBalance> = ref({mogul_balance: 0, mogul_balance_raw: '', mogul_to_whole_number: 0, mogul_to_whole_number_raw: ''})
    const CurrencyTokens : Ref<Array<CurrencyToken>> = ref([])

    // sidebar
    const isSidebarOpen : Ref<boolean> = ref(true)
    const headerHeight : Ref<number> = ref(0)

    // timestamp
    const currentTimestamp : Ref<number> = ref(0)
    let currentTimestampInterval : number = 0

    const startLoading = () => {
        loaderActive.value++
    }
    const stopLoading = () => {
        if (loaderActive.value > 0) loaderActive.value--
    }

    const isMobile = (): boolean => {
        return windowWidth.value < 1024
    }

    const resizeHandler = (): void => {
        windowWidth.value = window.innerWidth
    }

    //setters
    const setLoggedInUser = (data: UserData) => {
        loggedInUser.value = data
    }
    const setUserData = (data: User) => {
        userData.value = data
    }

    const selectNFT = (id : number) => {
        const foundNFT = walletNFTs.value.find(nft => nft.id === id)
        if (!foundNFT) return
        localStorage.setItem('selected_nft_id', foundNFT.id.toString())
        selectedNFT.value = foundNFT
    }

    const setWalletNFTs = (NFTs : NFT[]) => {
        walletNFTs.value = NFTs
        if (walletNFTs.value.length > 0) {
            const selectedNFTId = Number(localStorage.getItem('selected_nft_id'))
            if (!isNaN(selectedNFTId) && selectedNFTId !== 0) {
                const foundNFT = walletNFTs.value.find(nft => nft.id === selectedNFTId)
                if (foundNFT) {
                    selectNFT(foundNFT.id)
                    return
                }
            }
            selectNFT(walletNFTs.value[0].id)
        }
    }
    const getWalletNFTs = () : NFT[] => {
        return walletNFTs.value
    }

    const getSelectedNFT = () : NFT|undefined => {
        return selectedNFT.value
    }

    const disconnect = async() => {
        await chainStore.disconnectWalletConnectIfConnected()

        localStorage.removeItem('last_connection')
        localStorage.removeItem('loggedIn')
        localStorage.removeItem('JWT')
        localStorage.removeItem('userData')
        setLoggedInUser({ WalletAddress: '', IsLoggedIn: false, JWT: '' })
        walletNFTs.value = []
        selectedNFT.value = undefined
        MogulNFTs.value = []
        MogulBalance.value = {mogul_balance: 0, mogul_balance_raw: '', mogul_to_whole_number: 0, mogul_to_whole_number_raw: ''}
        CurrencyTokens.value = []
    }

    const isOnlyAddressConnected = () => {
        return !!loggedInUser.value.WalletAddress && !loggedInUser.value
    }

    const isAddressConnected = () => {
        return !!loggedInUser.value.WalletAddress
    }
    const isLoggedIn = () => {
        return loggedInUser.value.IsLoggedIn
    }

    const isLoading = () => {
        return loaderActive.value > 0
    }
    const getLoggedInUser = () => {
        return loggedInUser.value
    }

    const getUserData = () => {
        return userData.value
    }

    const openSidebar = (state : boolean) => {
        isSidebarOpen.value = state
    }
    const toggleSidebar = () => {
        isSidebarOpen.value = !isSidebarOpen.value
    }

    const setHeaderHeight = (height : number) => {
        headerHeight.value = height
    }
    const getHeaderHeight = () : number => {
        return headerHeight.value
    }

    const startCurrentTimestampInterval = () => {
        if (currentTimestamp.value) clearInterval(currentTimestampInterval)
        currentTimestampInterval = window.setInterval(() => {
            currentTimestamp.value = Number((new Date().getTime() / 1000).toFixed(0))
        }, 500)
    }
    const stopCurrentTimestampInterval = () => {
        if (currentTimestampInterval) clearInterval(currentTimestampInterval)
    }

    const loadMogulNFTs = async() => {
        if (!isLoggedIn()) return
        try {
            startLoading()
            const mogulNFTsResponse : AxiosResponse<Array<Mogul>> = await axios.get('/api/v1/moguls/token')
            MogulNFTs.value = mogulNFTsResponse.data
        } catch (e) {
            captureException(e)
        } finally {
            stopLoading()
        }
    }
    const loadTreasuryData = async() => {
        try {
            startLoading()

            let url = '/api/v1/moguls/treasury_claims/'
            const selectedNFT = getSelectedNFT()
            if (selectedNFT) url = `/api/v1/moguls/treasury_claims/${selectedNFT.id}`

            const res : AxiosResponse<TreasuryClaimData> = await axios.get(url)
            treasuryClaimData.value = res.data
        } catch (e) {
            captureException(e)
        } finally {
            stopLoading()
        }
    }
    const getMarineMoguls = () : Array<Mogul> => {
        return MogulNFTs.value ?? []
    }

    const getMergeableMarineMoguls = () : Array<Mogul> => {
        return getMarineMoguls().filter((m : Mogul) => {
            return m.rarity_per_level && m.rarity_per_level[m.level] === 'Epic' && !m.mergable && !m.merged // NFT to burn must be Epic and must not be mergable and must not be merged
        })
    }

    const loadWalletNFTs = async() => {
        const responseWalletNFts : AxiosResponse<Array<NFT>> = await axios.get('/api/v1/moguls/nfts')
        setWalletNFTs(responseWalletNFts.data)
    }

    const loadMOGULBalance = async() => {
        if (!isLoggedIn()) return
        try {
            startLoading()
            const responseMogulBalance : AxiosResponse<MogulBalance> = await axios.get('/api/v1/moguls/mogul_balance')
            MogulBalance.value = responseMogulBalance.data
        } catch (e) {
            captureException(e)
        } finally {
            stopLoading()
        }
    }

    const setLogin = async(JWT: string): Promise<void> => {
        try {
            const parsedUserData = parseJwt(JWT) as ParsedJWT

            if (!parsedUserData) return

            const loggedInUser: UserData = {
                WalletAddress: parsedUserData.wallet_address,
                IsLoggedIn: true,
                JWT
            }
            setLoggedInUser(loggedInUser)

            localStorage.setItem('JWT', JWT)
            localStorage.setItem('loggedIn', 'true')
            localStorage.setItem('userData', JSON.stringify(loggedInUser))
           await Promise.all([loadWalletNFTs(), loadMogulNFTs()])
        } catch (e) {
            captureException(e)
        }
    }

    const tryLoginWithJWT = async() => {
        const JWT = getJwt()
        if (!JWT) return

        const parsedJWT = parseJwt(JWT)
        if (!parsedJWT) return

        if (isJWTExpired(parsedJWT.exp)) {
            await disconnect()
            const router = useRouter()
            await router.replace({name: 'home'})
        }

        await setLogin(JWT)
    }

    const getMOGULBalance = () : MogulBalance => {
        return MogulBalance.value
    }
    const getCurrencyTokens = () : Array<CurrencyToken> => {
        return CurrencyTokens.value
    }

    const preloadImage = (newImage : string) => {
        if (preloadedImages.map(img => img.src).includes(newImage)) return
        const img = new Image()
        img.src = newImage
        preloadedImages.push(img)
    }

    return {
        windowWidth,
        isMobile,
        startLoading,
        stopLoading,
        resizeHandler,
        setLoggedInUser,
        setUserData,
        isOnlyAddressConnected,
        isAddressConnected,
        isLoggedIn,
        getLoggedInUser,
        getUserData,
        router,
        loggedInUser,
        disconnect,
        isLoading,
        isSidebarOpen,
        openSidebar,
        toggleSidebar,
        setHeaderHeight,
        getHeaderHeight,
        currentTimestamp,
        startCurrentTimestampInterval,
        stopCurrentTimestampInterval,
        setWalletNFTs,
        getWalletNFTs,
        getSelectedNFT,
        selectNFT,
        loadMogulNFTs,
        setLogin,
        tryLoginWithJWT,
        getMarineMoguls,
        getMergeableMarineMoguls,
        loadMOGULBalance,
        getMOGULBalance,
        getCurrencyTokens,
        preloadImage,
        loadTreasuryData,
        treasuryClaimData
    }
})
