<template>
    <div>
        <div class="py-8">
            <h1 class="text-3xl lg:text-4xl text-center font-semibold mb-6"><span class="uppercase">{{ $t('general.marine_moguls') }}</span> <span class="font-bold">{{ $t('general.nfts') }}</span></h1>
            <div
                class="grid grid-cols-1 lg:grid-cols-3 gap-6"
                style="grid-auto-rows: minmax(auto, auto)"
            >
                <div>
                    <NFTInfoCard class="mb-12" :nft-data="noNFTPresentationNFT"
                                 :current-state="currentNFTInfoCardState"
                                 :show-menu="currentNFTInfoCardState !== NFTInfoCardState.NoNFT" />
                    <MarineMoguls @mogul-selected-in-modal="onMogulSelectedInModal"/>
                    <HiddenPrizesClaimInfo class="mt-8"/>
                </div>
                <div class="lg:col-span-2 flex flex-col gap-6">
                    <!--                    <AirdropClaims :current-state="ClaimState.Claim"/>-->
                    <!--                    <TreasuryClaims/>-->
                    <StakingInfo/>
                    <MarketPlaceCard/>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
    import NFTInfoCard from '@/components/NFT/NFTInfoCard/NFTInfoCard.vue'
    // import AirdropClaims from '@/components/airdropClaims/AirdropClaims.vue'
    // import {ClaimState} from '@/enums/claim'
    // import TreasuryClaims from '@/components/treasuryClaims/TreasuryClaims.vue'
    import MarineMoguls from '@/components/marineMoguls/MarineMoguls.vue'
    import StakingInfo from '@/components/staking/StakingInfo.vue'
    import {computed, provide, ref, type Ref} from 'vue'
    import {useAppStore} from '@/stores/app'
    import {NFTInfoCardState} from '@/enums/NFTInfo'
    import type {NFT} from '@/types/Account'
    import MarketPlaceCard from '@/components/MarketPlaceCard.vue'
    import HiddenPrizesClaimInfo from '@/components/marineMoguls/HiddenPrizesClaimInfo.vue'
    const appStore = useAppStore()
    const presentationNFT : Ref<NFT> = ref({
        id: 0,
        level: 10,
        referral_link: ''
    })
    const mogulNFTIdSelectedInModal : Ref<number> = ref(-1)
    provide('mogul-selected-in-modal', mogulNFTIdSelectedInModal)

    const onMogulSelectedInModal = (mogulNFTId : number) => {
        mogulNFTIdSelectedInModal.value = mogulNFTId
    }

    // computed
    const currentNFTInfoCardState = computed(() => {
        return appStore.getSelectedNFT() ? NFTInfoCardState.Minimal : NFTInfoCardState.NoNFT
    })
    const noNFTPresentationNFT = computed(() => {
        return appStore.getSelectedNFT() ? appStore.getSelectedNFT() : presentationNFT.value
    })
</script>
