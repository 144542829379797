<template>
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.3863 1.08975H13.3469C13.1379 1.08975 12.9667 1.26086 12.9667 1.46993V3.02775H12.6267C12.4176 3.02775 12.2465 3.19886 12.2465 3.40794C12.2465 3.61701 12.4176 3.78812 12.6267 3.78812H12.9667V5.51672C12.9667 5.7258 13.1379 5.89691 13.3469 5.89691H15.0384V7.35077C15.0384 7.55985 15.2095 7.73096 15.4186 7.73096C15.6277 7.73096 15.7988 7.55985 15.7988 7.35077V5.89691H17.3863C17.5954 5.89691 17.7665 5.7258 17.7665 5.51672V1.46993C17.7665 1.26334 17.5994 1.09381 17.3937 1.08982V1.08975H17.3863ZM17.0135 5.13654H13.7271V3.40794V1.85012H17.0135V5.13654Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.3"
        />
        <path
            d="M17.3946 13.1186H15.8071V12.8527C15.8071 12.6437 15.636 12.4726 15.4269 12.4726C15.2178 12.4726 15.0467 12.6437 15.0467 12.8527V13.1186H13.3553C13.1462 13.1186 12.9751 13.2897 12.9751 13.4987V15.2273H11.5212C11.3121 15.2273 11.141 15.3985 11.141 15.6075C11.141 15.8166 11.3121 15.9877 11.5212 15.9877H12.9751V17.5455C12.9751 17.7546 13.1462 17.9257 13.3553 17.9257H17.402C17.6111 17.9257 17.7822 17.7546 17.7822 17.5455V13.4987C17.7822 13.2897 17.6111 13.1186 17.402 13.1186H17.3946ZM17.0144 17.1654H13.728V15.6075V13.8789H17.0144V17.1654Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.3"
        />
        <path
            d="M3.21847 10.211C3.42754 10.211 3.59865 10.0399 3.59865 9.83084V7.99679C3.59865 7.78771 3.42754 7.6166 3.21847 7.6166C3.00939 7.6166 2.83828 7.78771 2.83828 7.99679V9.83084C2.83828 10.0399 3.00939 10.211 3.21847 10.211Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.3"
        />
        <path
            d="M6.01129 15.2269H5.67122V13.4983C5.67122 13.2892 5.50011 13.1181 5.29104 13.1181H3.59956V11.6643C3.59956 11.4552 3.42845 11.2841 3.21938 11.2841C3.0103 11.2841 2.83919 11.4552 2.83919 11.6643V13.1181H1.25167C1.0426 13.1181 0.871484 13.2892 0.871484 13.4983V17.5451C0.871484 17.7542 1.0426 17.9253 1.25167 17.9253H5.29846C5.50754 17.9253 5.67865 17.7542 5.67865 17.5451V15.9873H6.01872C6.22779 15.9873 6.3989 15.8162 6.3989 15.6071C6.3989 15.398 6.22779 15.2269 6.01872 15.2269H6.01129ZM4.91828 17.1649H1.63185V13.8785H4.91828V17.1649Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.3"
        />
        <path
            d="M15.4255 11.3985C15.6346 11.3985 15.8057 11.2274 15.8057 11.0183V9.18429C15.8057 8.97521 15.6346 8.8041 15.4255 8.8041C15.2164 8.8041 15.0453 8.97521 15.0453 9.18429V11.0183C15.0453 11.2274 15.2164 11.3985 15.4255 11.3985Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.3"
        />
        <path
            d="M6.76297 3.02033L6.7704 3.02775H5.67865V1.23975V1.08975H5.52865H1.02148H0.871484V1.23975V5.73948V5.88948H1.02148H2.83919V6.1553C2.83919 6.36437 3.0103 6.53548 3.21938 6.53548C3.42845 6.53548 3.59956 6.36437 3.59956 6.1553V5.88948H5.52122H5.67122V5.73948V3.78069H7.12509C7.33416 3.78069 7.50527 3.60958 7.50527 3.40051C7.50527 3.19144 7.33416 3.02033 7.12509 3.02033H6.76297Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.3"
        />
        <path
            d="M10.8006 3.02725H8.96651C8.75744 3.02725 8.58633 3.19836 8.58633 3.40743C8.58633 3.6165 8.75744 3.78762 8.96651 3.78762H10.8006C11.0096 3.78762 11.1807 3.6165 11.1807 3.40743C11.1807 3.19836 11.0096 3.02725 10.8006 3.02725Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.3"
        />
    </svg>
</template>
