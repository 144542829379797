import './assets/main.css'
import 'floating-vue/dist/style.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import i18n from '@/libs/i18n'
import App from './App.vue'
import router from './router'
import {useAppStore} from '@/stores/app'
import Vue3Toastify, {toast, type ToastContainerOptions} from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import {useChainStore} from '@/stores/chain'
import VueMatomo from 'vue-matomo'
import {checkSpectatorLogin} from '@/libs/spectator'
import {createSentry} from '@/libs/sentry'
import {captureException} from '@sentry/vue'
import FloatingVue from 'floating-vue'
const initialize = async() => {

    const app = createApp(App)
    app.use(i18n)
    app.use(createPinia())
    app.use(Vue3Toastify, {
        autoClose: 3000
    } as ToastContainerOptions)
    app.use(VueMatomo, {
        host: 'https://matomo.metfi.io',
        siteId: 15,
        router
    })
    app.use(FloatingVue)
    createSentry(app)

    try {
        const appStore = useAppStore()
        const chainStore = useChainStore()
        await checkSpectatorLogin()
        await Promise.all([appStore.tryLoginWithJWT(), chainStore.getWebConfig()])
    } catch (e) {
        const t = i18n.global.t
        toast.error(t('errors.there_was_an_error_placeholder', {'error_message': t('errors.loading_data')}))
        captureException(e)
    }

    app.use(router)
    app.mount('#app')
}

initialize()
