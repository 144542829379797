<template>
    <div v-once class="flex flex-wrap justify-center gap-4">
        <template v-for="(social, index) of socials" :key="`social_${index}`">
            <a v-if="!props.hiddenSocials.includes(social.name)" :href="social.url" target="_blank">
                <div class="flex w-7 h-7 justify-center items-center text-white bg-green-extra-light hover:bg-green-light hover:text-white transition-all duration-300 rounded-full">
                    <component :is="social.icon as TablerIconComponent" v-if="social.icon_type === 'icon'" class="w-4"/>
                    <component :is="social.icon" v-else class="w-4" />
                </div>
            </a>
        </template>
    </div>
</template>
<script setup lang="ts">
    import { type ComponentOptionsBase, type Ref, shallowRef } from 'vue'
    import BrandXIcon from '@/assets/icons/BrandXIcon.vue'
    import {
        BrandTelegramIcon,
        BrandDiscordIcon,
        type TablerIconComponent
    } from 'vue-tabler-icons'

    const props = defineProps({
        hiddenSocials: {
            type: Array<string>,
            default: () => []
        }
    })

    interface Social {
        icon:
            | string
            | TablerIconComponent
            | ComponentOptionsBase<any, any, any, any, any, any, any, any>
        icon_type: string
        name: string
        url: string
    }

    // data
    const socials: Ref<Social[]> = shallowRef([
        { name: 'telegram', url: 'https://t.me/MetFiChat', icon: BrandTelegramIcon, icon_type: 'icon' },
        {
            name: 'discord',
            url: 'https://discord.gg/R7YqC5xVSW',
            icon: BrandDiscordIcon,
            icon_type: 'icon'
        },
        {
            name: 'twitter',
            url: 'https://twitter.com/MetFi_DAO',
            icon: BrandXIcon,
            icon_type: 'component'
        }
    ])
</script>
