<template>
    <div v-if="items" class="w-full">
        <div ref="container" class="keen-slider">
            <div v-for="(item, index) of items" :key="`carousel-${index}`" class="keen-slider__slide">
                <slot name="slide" :item="item" :index="index"/>
            </div>
        </div>

        <slot v-if="slider" name="pagination" :slider="slider"/>
    </div>
</template>

<script setup lang="ts">
    import { useKeenSlider, type KeenSliderInstance } from 'keen-slider/vue.es'
    import 'keen-slider/keen-slider.min.css'
    import {onBeforeUnmount, type PropType} from 'vue'
    import type {Mogul} from '@/types/Mogul'

    // props
    const props = defineProps({
        items: {
            type: Array as PropType<Mogul[]>,
            default: () => { return [] },
            required: true
        },
        breakpoints: {
            type: Object,
            default: () => {
                return {
                    '(min-width: 1px)': {
                        slides: { perView: 1, spacing: 10 }
                    }
                }
            }
        }
    })

    const emits = defineEmits<{
        (e: 'slide-changed', newIndex: number): void,
        (e: 'sufficient-slides-for-breakpoint', isSufficient: boolean): void
    }>()

    interface SlidesOpts {
        perView: number,
        spacing: number
    }

    // data
    const ResizePlugin = (slider: KeenSliderInstance) => {
        const observer = new ResizeObserver(() => {
            // when lazy loading items from API, call forceUpdateSlider function manually
            // for smoother transition

            let slidesCountInOptions = 4 // safe value
            const slidesOptions : SlidesOpts | undefined = slider.options.slides as SlidesOpts | undefined
            if (slidesOptions) {
                slidesCountInOptions = slidesOptions.perView

                // disable loop if there are less slides than there is slides per breakpoint
                if (slider.options.loop) { // only disable loop if it's enabled
                    if (slider.slides.length <= slidesCountInOptions) {
                        slider.options.loop = false
                    } else {
                        slider.options.loop = true
                    }
                    emits('sufficient-slides-for-breakpoint', slider.options.loop)
                }
            }

            slider.update()
        })
        slider.on('created', () => {
            observer.observe(slider.container)
        })
        slider.on('destroyed', () => {
            observer.unobserve(slider.container)
        })
    }

    const sliderOptions = {
        loop: true,
        breakpoints: props.breakpoints,
        slides: { perView: 1, spacing: 1 },
        vertical: false,
        drag: true,
        slideChanged: (s : any) => {
            emits('slide-changed', s.track.details.rel)
        }
    }

    const [container, slider] = useKeenSlider<HTMLDivElement>(sliderOptions, [ResizePlugin])

    const forceUpdateSlider = () => {
        // eslint-disable-next-line no-use-before-define
        if (!slider.value) return
        // eslint-disable-next-line no-use-before-define
        slider.value.update()
    }
    const moveToIndex = (index : number) => {
        if (!slider.value) return
        slider.value?.moveToIdx(index)
    }

    // lifecycle hooks
    onBeforeUnmount(() => {
        if (slider.value) slider.value.destroy()
    })


    defineExpose({moveToIndex, forceUpdateSlider})
</script>
