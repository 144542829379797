<template>
    <div v-if="data" class="bg-black text-white rounded-lg flex flex-col enabled-mogul" :class="{'disabled-mogul': disabled}">
        <template v-if="currentState === MogulState.Small">
            <div class="pt-1 pb-3 px-1 flex flex-col h-full" :class="{'show-overlay': showBlackOverlay}">
                <img class="w-full h-full min-h-[80px] rounded-lg prevent-select" :draggable="!disabled"
                     :src="hasCustomImageSrcSet ? customImageSrc : getFullImageNameForMogul(data.nft_id, '256_256', data.level)" alt="mogul"/>
                <div v-if="hasButton" class="flex items-center justify-between gap-1 mt-4 px-2">
                    <p class="text-lg leading-4 text-blue-light font-semibold break-all nft-id">#{{data.nft_id}}</p>
                    <Button size="custom" class="w-10 h-10 flex items-center justify-center !text-xs" variant="dark-blue" @click="emit('click')">
                        <slot name="button-icon"/>
                    </Button>
                </div>
                <p v-else class="text-lg leading-4 text-blue-light text-center font-semibold break-all pt-2 mt-auto nft-id"
                   :class="{'text-danger': isLastInQueue && data.staking_status === StakingStatus.InWallet}">#{{data.nft_id}}</p>
            </div>
        </template>
        <template v-else-if="currentState === MogulState.Medium">
            <div class="p-4 mx-auto min-h-[457px]">
                <div class="image-placeholder min-w-full h-full w-full max-w-[270px] max-[320px]:max-h-[270px] max-h-[298px] rounded-lg prevent-select max-[320px]:min-h-0 min-[320px]:min-w-[270px] min-h-[298px]">
                    <img class="rounded-lg prevent-select max-[320px]:min-h-0 min-[320px]:min-w-[250px] min-h-[298px]"
                         :src="hasCustomImageSrcSet ? customImageSrc: getFullImageNameForMogul(data.nft_id, '512_512', data.level)" alt="Marine Mogul" :draggable="!disabled"/>
                </div>
                <div class="flex flex-wrap items-center justify-between gap-x-2 mt-4">
                    <p class="text-xl lg:text-2xl font-bold">{{$t('general.marine_mogul')}}</p>
                    <p class="text-xl lg:text-2xl text-blue-light break-all">#{{data.nft_id}}</p>
                </div>
                <hr class="border-white my-3"/>
                <div class="flex flex-col gap-[2px]">
                    <p class="text-lg lg:text-xl font-light">{{$t('moguls.rarity')}}: <span class="font-semibold">{{data.tier}}</span></p>
                    <p class="text-lg lg:text-xl font-light">{{$t('moguls.character')}}: <span class="font-semibold uppercase">{{characterNameByLevel(data.level)}}</span></p>
                    <slot name="traits"/>
                    <!--                    <p v-if="data.prize" class="text-lg lg:text-xl font-light">{{$t('moguls.prize')}}: <span class="font-semibold text-blue-dark">{{data.prize}} USDT</span></p>-->
                    <!--                    <p v-if="data.required_rarity" class="text-lg lg:text-xl font-light">{{$t('moguls.required_rarity')}}: <span class="font-semibold">{{data.required_rarity}}</span></p>-->
                </div>
            </div>
        </template>
        <template v-else-if="currentState === MogulState.Large">
            <div class="p-4 sm:min-w-[400px] sm:max-w-[400px] lg:max-w-full lg:min-w-full lg:w-fit w-full mx-auto">
                <img class="w-full min-h-[300px] object-cover rounded-lg prevent-select" :draggable="!disabled"
                     :src="hasCustomImageSrcSet ? customImageSrc : getFullImageNameForMogul(data.nft_id, 'full', data.level)" alt="mogul"/>
                <CardButtons class="mt-6" @click="onCardButtonClicked" :mogulNFT="data" v-if="showCardButtons"/>
                <hr class="border-white my-8"/>
                <div class="flex flex-wrap items-center justify-between gap-x-2">
                    <p class="text-xl lg:text-2xl font-bold">{{$t('general.marine_mogul')}}</p>
                    <p class="text-2xl lg:text-3xl text-blue-light break-all">#{{data.nft_id}}</p>
                </div>
                <div class="flex flex-wrap items-center justify-between gap-x-2 mt-2">
                    <p class="text-lg lg:text-xl font-bold">{{data.tier}}</p>
                    <p class="text-lg lg:text-xl text-blue-dark">{{$t('moguls.rank')}}: #{{data.initial_rarity_rank}}</p>
                </div>
                <div class="flex flex-wrap mt-2" v-if="data.is_tide_turner">
                    <p class="text-lg text-danger">{{$t('moguls.the_tide_turner')}}</p>
                </div>
                <div class="flex flex-wrap flex-row justify-between mt-2" v-if="isLastInQueue && data.staking_status === StakingStatus.InWallet">
                    <p class="text-lg font-semibold text-danger">{{$t('my_marine_moguls.last_in_queue')}}</p>
                    <TooltipComponent>
                        <InfoCircleIcon class="h-full text-danger cursor-pointer"/>
                        <template #text>
                            {{$t('my_marine_moguls.lifo_explanation')}}
                        </template>
                    </TooltipComponent>
                </div>
                <hr class="border-white my-4"/>
                <div class="flex flex-col gap-1 mt-6">
                    <p class="text-xl lg:text-2xl font-semibold mb-2">{{$t('moguls.traits')}}</p>
                    <p class="text-lg lg:text-xl font-light">{{$t('moguls.headwear')}}: <span class="font-semibold">{{data.headwear}}</span></p>
                    <p class="text-lg lg:text-xl font-light">{{$t('moguls.eyewear')}}: <span class="font-semibold">{{data.eyewear}}</span></p>
                    <p class="text-lg lg:text-xl font-light">{{$t('moguls.neckwear')}}: <span class="font-semibold">{{data.neckwear}}</span></p>
                    <p class="text-lg lg:text-xl font-light">{{$t('moguls.attire')}}: <span class="font-semibold">{{data.attire}}</span></p>
                    <p class="text-lg lg:text-xl font-light">{{$t('moguls.item')}}: <span class="font-semibold">{{data.handheld_items}}</span></p>
                    <p class="text-lg lg:text-xl font-light">{{$t('moguls.background')}}: <span class="font-semibold">{{data.backgrounds}}</span></p>
                    <p class="text-lg lg:text-xl font-light">{{$t('moguls.character')}}: <span class="font-semibold">{{characterNameByLevel(data.level)}}</span></p>
                    <slot name="traits"/>
                </div>
                <hr class="border-white my-8"/>
                <div class="flex flex-col gap-1 mb-8">
                    <p class="text-xl lg:text-2xl font-bold">{{$t('moguls.craftable')}}: <span class="font-bold uppercase" :class="[data.upgradeable && data.level<10 ? 'text-blue-extra-light' : 'text-danger']">{{ isCraftable}}</span></p>
                    <p class="text-xl lg:text-2xl font-bold">{{$t('moguls.mergeable')}}: <span class="font-bold uppercase" :class="[data.mergable && !data.merged ? 'text-blue-extra-light' : 'text-danger']">{{isMergable}}</span></p>
                    <p class="text-xl lg:text-2xl font-bold">{{$t('moguls.hidden_prize')}}: <span class="font-bold uppercase" :class="[data?.has_hidden_prize && !data.hidden_prize_claimed ? 'text-blue-extra-light' : 'text-danger']">{{hasPrize}}</span></p>
                </div>
            </div>
        </template>
        <slot name="data"/>
    </div>
</template>

<script setup lang="ts">
    import {MogulState, StakingStatus} from '@/enums/mogul'
    import {computed, type PropType} from 'vue'
    import type {Mogul, MogulCardAction} from '@/types/Mogul'
    import CardButtons from '@/components/marineMoguls/components/CardButtons.vue'
    import Button from '@/components/Button.vue'
    import {characterNameByLevel} from '@/libs/helpers'
    import {getFullImageNameForMogul} from '@/libs/moguls'
    import {InfoCircleIcon} from 'vue-tabler-icons'
    import TooltipComponent from '@/components/TooltipComponent.vue'
    import {useI18n} from 'vue-i18n'

    const t = useI18n().t
    
    const props = defineProps({
        data: {
            type: Object as PropType<Mogul>
        },
        currentState: {
            type: Number,
            default: MogulState.Small
        },
        hasButton: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        showCardButtons: {
            type: Boolean,
            default: true
        },
        showBlackOverlay: {
            type: Boolean,
            default: false
        },
        customImageSrc: {
            type: String,
            default: ''
        },
        hasCustomImageSrcSet: {
            type: Boolean,
            default: false
        },
        isLastInQueue : {
            type: Boolean,
            default: false
        }
    })

    const isMergable = computed(() => {
        if (!props.data) return t('moguls.no')
        if (props.data.merged) return t('moguls.merged')
        else return props.data.mergable ? t('moguls.yes') : t('moguls.no')

    })

    const hasPrize = computed(() => {
        if (!props.data) return t('moguls.no')
        if (props.data.hidden_prize_claimed) return t('moguls.prize_claimed')
        else return props.data.has_hidden_prize ? t('moguls.yes') : t('moguls.no')
    })

    const isCraftable = computed(() => {
        if (!props.data) return t('moguls.no')
        if (props.data.level >= 10) return t('moguls.upgraded')
        else return props.data.upgradeable ? t('moguls.yes') : t('moguls.no')
    })

    const emit = defineEmits<{(e:'click'): void, (e: 'action-clicked', value: MogulCardAction):void}>()

    const onCardButtonClicked = (type : number) => {
        if (!props.data) return
        const data : MogulCardAction = {type, nft_id: props.data!.nft_id}
        emit('action-clicked', data)
    }

</script>

<style scoped>
img {
  background-color: black;
  transition: filter .3s;
}
.show-overlay img,
.show-overlay .nft-id {
  filter: brightness(0.5);
}
.show-overlay:hover img,
.show-overlay:hover .nft-id{
  filter: brightness(1);
}
.image-placeholder {
  background: #ffffff09;
}
.disabled-mogul {
  filter: grayscale(100%) !important;
}
.enabled-mogul {
  filter: grayscale(0);
  transition: filter 1s ease-in-out;
}
</style>
