<template>
    <div :class="pillClasses" class="pill cursor-pointer">
        <slot></slot>
    </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
    background: {
        type: String,
        default: 'default',
        validator(value: string) {
            return ['default'].includes(value)
        }
    },
    padding: {
        type: String,
        default: 'p-4'
    }
})

const pillClasses = computed(() => {
    return [props.padding]
})
</script>
<style scoped>
.pill {
    background-color: var(--color-light-green);
    border-radius: 50px;
    width: fit-content;
}
</style>
