<template>
    <Button size="small" class="back-button !p-0" v-if="displayButton" @click="onGoBackClicked">
        <div class="flex px-2 py-0.5 items-center">
            <ChevronsLeftIcon size="22"/>
            <span class="pb-0.5 pr-1 text-sm">{{ $t('general.back') }}</span>
        </div>

    </Button>
</template>
<script lang="ts" setup>
    import Button from '@/components/Button.vue'
    import {ChevronsLeftIcon} from 'vue-tabler-icons'
    import {computed} from 'vue'
    import {useRoute, useRouter} from 'vue-router'

    const route = useRoute()
    const router = useRouter()
    const routesToDisplayBackButton : Array<String> = ['craft', 'merge', 'prize', 'send']

    const displayButton = computed(() => {
        if (!route || !route.name) return false
        return routesToDisplayBackButton.includes(route.name.toString())
    })
    const onGoBackClicked = async() => {
        if (router.options.history.state && router.options.history.state.back) {
            router.go(-1)
        } else {
            await router.replace({name: 'home'})
        }
    }
</script>
