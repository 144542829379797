<template>
    <div>
        <p class="text-xl lg:text-2xl font-bold">{{$t('staking.my_staking')}}:</p>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
            <div class="flex flex-wrap items-center justify-between gap-1 p-4 bg-blue-dark-2 border-[1px] border-blue-light rounded-lg">
                <p class="text-sm lg:text-base">{{$t('staking.total_rewards')}}:</p>
                <h1 class="text-2xl lg:text-3xl font-semibold flex-grow text-right">{{myStakingData ? formatNumber(myStakingData.total_rewards) : 0}} MOGUL</h1>
            </div>
            <div class="flex flex-wrap items-center justify-between gap-1 p-4 bg-green-extra-light-2/80 border-[1px] border-blue-light rounded-lg">
                <p class="text-sm lg:text-base">{{$t('staking.next_reward')}}:</p>
                <h1 class="text-2xl lg:text-3xl font-semibold flex-grow text-right">{{myStakingData ? formatNumber(myStakingData.next_reward) : 0}} MOGUL</h1>
            </div>
        </div>

        <div class=" p-4 border-[1px] border-blue-light rounded-lg mt-4">
            <div class="grid grid-cols-2 md:grid-cols-3 gap-y-2 gap-x-1">
                <div class="flex items-center">
                    <p class="text-sm lg:text-base">{{$t('staking.claimable_rewards')}}:</p>
                </div>

                <div class="flex items-center">
                    <h1 class="text-2xl lg:text-3xl font-semibold">{{myStakingData ? formatNumber(myStakingData.claimable_rewards) : 0}} MOGUL</h1>
                </div>
                <!-- min-h-[60px] prevents UI from jumping when clicking "go back" -->
                <div class="flex flex-col gap-1 justify-center items-center min-h-[60px] col-span-2 md:col-span-1 pt-4 md:pt-0">
                    <template v-if="!isClaimPartSelected">
                        <Button class="min-[230px]:!min-w-32 uppercase mx-auto lg:mx-0 w-fit" size="small" :disabled="!isRewardClaimable" @click="onClaimClicked">{{$t('staking.claim_all')}}</Button>
                        <Button class="min-[230px]:!min-w-32 uppercase mx-auto lg:mx-0 w-fit" size="small" :disabled="!isRewardClaimable" @click="onClaimPartClicked">{{$t('staking.claim_partial')}}</Button>
                    </template>
                    <div class="flex justify-end w-full pr-2 pt-2" v-else>
                        <Button class="uppercase mx-auto lg:mx-0 text-white !p-1.5" circle @click="isClaimPartSelected = false">
                            <ChevronLeftIcon/>
                        </Button>
                    </div>
                </div>
            </div>
            <div class="flex justify-center mt-10 gap-2 flex-col items-center" v-if="isClaimPartSelected">
                <div>{{$t('staking.unstake_quantity_question')}}</div>
                <div class="flex gap-2 ">
                    <Input v-model="unstakeInputDisplay" ref="unstakeInputDisplayRef"/>
                    <Button square size="custom" class="uppercase py-2 px-4 sm:min-w-[80px]"
                            @click="onMaxStakeClicked">{{$t('general.max')}}</Button>
                </div>
                <Button font-size="!text-xl lg:!text-2xl uppercase mt-2" :disabled="!isCommitPartialButtonValid" @click="onClaimClicked"
                >{{$t('treasury_claims.claim')}}</Button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import Button from '@/components/Button.vue'
    import {computed, onMounted, ref, type Ref} from 'vue'
    import type {StakingData} from '@/types/Staking'
    import {formatNumber, processContractRunnerError} from '@/libs/helpers'
    import {captureException} from '@sentry/vue'
    import {toast} from 'vue3-toastify'
    import {useChainStore} from '@/stores/chain'
    import {useAppStore} from '@/stores/app'
    import {useI18n} from 'vue-i18n'
    import {formatUnits, parseUnits} from 'ethers'
    import type {AxiosResponse} from 'axios'
    import axios from '@/libs/axios'
    import Input from '@/components/Input.vue'
    import {ChevronLeftIcon} from 'vue-tabler-icons'

    const chainStore = useChainStore()
    const appStore = useAppStore()
    const t = useI18n().t
    const myStakingData : Ref<StakingData|undefined> = ref(undefined)
    const isClaimPartSelected : Ref<Boolean> = ref(false)
    const amountToUnstake : Ref<string> = ref('')
    const unstakeInputDisplayRef = ref()

    // computed
    const isRewardClaimable = computed(() => {
        return myStakingData.value && myStakingData.value.claimable_rewards !== 0
    })
    const isCommitPartialButtonValid = computed(() =>  {
        if (!myStakingData.value || isNaN(Number(amountToUnstake.value)) || isNaN(Number(myStakingData.value.claimable_rewards_raw))) return

        const isValid = Number(amountToUnstake.value) <= Number(myStakingData.value.claimable_rewards_raw)
        if (!isValid && unstakeInputDisplayRef.value) {
            unstakeInputDisplayRef.value!.showInvalidState()
        }
        return isValid
    })

    // functions

    const onMaxStakeClicked = () => {
        if (!myStakingData.value) return
        amountToUnstake.value = myStakingData.value.claimable_rewards_raw
    }

    const loadData = async() => {
        try {
            appStore.startLoading()
            const res : AxiosResponse<StakingData> = await axios.get('/api/v1/moguls/staking_user')
            myStakingData.value = res.data
        } catch (e) {
            captureException(e)
            toast.error(t('errors.there_was_an_error_placeholder', {'error_message': t('errors.loading_data')}))
        } finally {
            appStore.stopLoading()
        }
    }
    const onClaimClicked = async() => {

        if (!isRewardClaimable.value) return

        try {
            appStore.startLoading()

            const marineMogulsStakingContract = await chainStore.getMarineMogulsStakingInterface()
            const signer = await chainStore.getSigner()

            const tx = await marineMogulsStakingContract.connect(signer).claimPendingRewards(isClaimPartSelected.value ? amountToUnstake.value as string : 0)
            await tx.wait(3)

            if (myStakingData.value) {
                try {
                    (window as any)._paq.push(['trackEvent', 'Stake', 'Claim Rewards', 'MOGUL', isClaimPartSelected.value ? Number(amountToUnstake.value) : Number(myStakingData.value!.claimable_rewards_raw)])
                } catch (e) {}
            }

            await Promise.all([loadData(), appStore.loadMOGULBalance(), appStore.loadMogulNFTs()])

            isClaimPartSelected.value = false
            amountToUnstake.value = ''
        } catch (e) {
            processContractRunnerError(e)
            captureException(e)
            toast.error(t('errors.there_was_an_error_placeholder', {'error_message': t('errors.claim_rewards_error')}))
        } finally {
            appStore.stopLoading()
        }
    }

    const onClaimPartClicked = () => {
        isClaimPartSelected.value = true
    }

    // computed
    const unstakeInputDisplay = computed({
        get() {
            if (!amountToUnstake.value) return 0
            return Number(formatUnits(amountToUnstake.value, 18))
        },
        set(val) {
            if (!val || isNaN(Number(val))) {
                amountToUnstake.value = ''
                return
            }
            amountToUnstake.value = parseUnits(String(val)).toString()
        }
    })

    onMounted(async() => {
        await loadData()
    })


</script>
