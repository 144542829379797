<template>
    <div v-if="appstore.isLoggedIn()" class="header-menu relative">
        <Pill padding="pl-4 md:pl-8 pr-2 py-1 flex items-center !min-w-fit !w-fit relative z-[2]" @click="openMenu">
            <div class="flex items-center justify-between">
                <span class="text-base md:text-lg font-bold pr-4">{{ getShortAddress }}</span>
                <Jazzicon :diameter="26" :address="appstore.getLoggedInUser().WalletAddress" class="h-[26px] rounded-[999px]"/>
            </div>
        </Pill>
        <transition>
            <div v-if="showMenu" class="border-[1px] border-default bg-white rounded-b-[5px] z-[1] absolute -mt-4 w-full pt-5 pb-3 px-2">
                <ul>
                    <li class="mt-1 flex">
                        <a class="flex items-center gap-1 text-lg font-medium cursor-pointer" @click="logout">
                            <IconChevronRight :size="18"/> {{$t('general.logout')}}
                        </a>
                    </li>
                </ul>
                <SocialsComponent class="mt-4"/>
            </div>
        </transition>
    </div>
    <Button v-else @click="router.push({name:'login'})" variant="light-green" >{{$t('general.login')}}</Button>
</template>
<script setup lang="ts">
    import Pill from '@/components/Pill.vue'
    import {IconChevronRight} from '@tabler/icons-vue'
    import {useRoute, useRouter} from 'vue-router'
    import {useAppStore} from '@/stores/app'
    import Button from '@/components/Button.vue'
    import {computed, type Ref, ref, watch} from 'vue'
    import Jazzicon from '@/components/Jazzicon.vue'
    import SocialsComponent from '@/components/social/SocialsComponent.vue'

    // data
    const route = useRoute()
    const router = useRouter()
    const appstore = useAppStore()
    const showMenu: Ref<boolean> = ref(false)

    // methods
    const openMenu = async() => {
        showMenu.value = !showMenu.value
    }

    const logout = async() => {
        showMenu.value = false
        await router.push({name:'login'})
        if (appstore.isLoggedIn()) {
            await appstore.disconnect()
        }
    }

    const getShortAddress = computed(() => {
        if (appstore.isLoggedIn()) {
            return `${appstore.loggedInUser.WalletAddress.substring(0, 4)}...${appstore.loggedInUser.WalletAddress.substring(appstore.loggedInUser.WalletAddress.length - 6, appstore.loggedInUser.WalletAddress.length)}`
        } else {
            return ''
        }
    })

    const outsideClickListener = (event: Event) => {
        const sidebarEl = document.querySelector('.header-menu')

        if (sidebarEl)  showMenu.value = sidebarEl.isSameNode(event.target as HTMLElement) ||
            sidebarEl.contains(event.target as HTMLElement)
        else showMenu.value = false
    }

    // watch
    watch(() => route.name, () => {
        showMenu.value = false
    })

    watch(showMenu, (newVal) => {
        if (newVal) {
            document.addEventListener('click', outsideClickListener)
        } else {
            document.removeEventListener('click', outsideClickListener)
        }
    })
</script>
<style scoped>
.v-enter-active, .v-leave-active {
  transition: opacity 0.3s ease;
}
.v-enter-from, .v-leave-to {
  opacity: 0;
}
</style>
