<template>
    <div class="flex items-center">
        <div class="line relative">
            <div class="absolute flex justify-between w-full dots">
                <span
                    v-for="i of 11"
                    v-bind:key="i"
                    class="dot max-[360px]:mt-[2px] max-[360px]:h-[5px] h-[10px]"
                    :class="dotColorClass(i - 1)"
                >
                </span>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
    const props = defineProps({
        level: {
            type: Number,
            required: true
        }
    })

    const dotColorClass = (i: number) => {
        if (i === props.level) return `selected max-[360px]:h-[8px] h-[16px] max-[360px]:!mt-[0px] -mt-[3px] nft-level-${i}`
        else if (i < props.level) return `nft-level-${i}`
        return ''
    }
</script>
<style scoped>
.line {
    height: 3px;
    background-color: #ccdddc;
    width: 100%;
    gap: 10px;
    display: flex;
    justify-content: space-evenly;
}
.dots {
    margin-top: -3px;
}
.dot {
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: #ccdddc;
}
/*.selected {
  margin-top: -3px;
}*/

.nft-level-0 {
    background-color: var(--nft-level-color-0);
}
.nft-level-1 {
    background-color: var(--nft-level-color-1);
}
.nft-level-2 {
    background-color: var(--nft-level-color-2);
}
.nft-level-3 {
    background-color: var(--nft-level-color-3);
}
.nft-level-4 {
    background-color: var(--nft-level-color-4);
}
.nft-level-5 {
    background-color: var(--nft-level-color-5);
}
.nft-level-6 {
    background-color: var(--nft-level-color-6);
}
.nft-level-7 {
    background-color: var(--nft-level-color-7);
}
.nft-level-8 {
    background-color: var(--nft-level-color-8);
}
.nft-level-9 {
    background-color: var(--nft-level-color-9);
}
.nft-level-10 {
    background-color: var(--nft-level-color-10);
}
</style>
