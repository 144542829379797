<template>
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.3414 6.86865H14.8305V8.25315C14.8305 8.33188 14.9007 8.39717 14.9854 8.39717H16.6521C16.7368 8.39717 16.807 8.3338 16.807 8.25315V6.86865H18.2961C18.3808 6.86865 18.451 6.80528 18.451 6.72463V5.17498C18.451 5.09625 18.3828 5.03096 18.2961 5.03096H16.807V3.64646C16.807 3.56773 16.7389 3.50244 16.6521 3.50244H14.9854C14.9007 3.50244 14.8305 3.56581 14.8305 3.64646V5.03096H13.3414C13.2567 5.03096 13.1865 5.09433 13.1865 5.17498V6.72463C13.1865 6.80336 13.2567 6.86865 13.3414 6.86865ZM13.4963 5.319H14.9854C15.0701 5.319 15.1403 5.25563 15.1403 5.17498V3.79048H16.4993V5.17498C16.4993 5.25371 16.5674 5.319 16.6542 5.319H18.1433V6.58253H16.6542C16.5695 6.58253 16.4993 6.6459 16.4993 6.72655V8.11105H15.1403V6.72655C15.1403 6.64782 15.0722 6.58253 14.9854 6.58253H13.4963V5.319Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.3"
        />
        <path
            d="M3.78039 4.38649C3.79613 6.65622 5.78736 8.49222 8.21933 8.47568C10.6513 8.45914 12.613 6.60108 12.5973 4.32951C12.5815 2.05978 10.5903 0.225619 8.15834 0.240322C5.72636 0.255025 3.76268 2.11492 3.78039 4.38649ZM8.16227 0.780647C10.2755 0.767782 12.005 2.36119 12.0188 4.33503C12.0326 6.30887 10.3266 7.92433 8.21343 7.93719C6.10021 7.95006 4.37068 6.35665 4.3569 4.38281C4.34313 2.40897 6.04905 0.793512 8.16227 0.780647Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.3"
        />
        <path
            d="M15.7791 13.9674C15.7023 11.376 13.9688 9.34521 11.8281 9.34521H4.5479C2.40713 9.34521 0.671698 11.376 0.596929 13.9674L0.563477 15.1565H15.8105L15.7771 13.9674H15.7791ZM1.1577 14.618L1.17541 13.9839C1.24231 11.6866 2.72392 9.88554 4.5479 9.88554H11.8281C13.6521 9.88554 15.1337 11.6848 15.2006 13.9821L15.2183 14.618H1.15573H1.1577Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.3"
        />
    </svg>
</template>
