<template>
    <div :class="getCardClasses()" :style="getStyles()" class="card">
        <div class="w-full flex flex-col h-full">
            <div
                v-if="slots.title"
                :ref="cardName"
                :class="{ 'text-center': titleCenter, 'text-left': !titleCenter }"
                class="title text-2xl font-bold flex items-center"
            >
                <span class="break-words w-full">
                    <slot name="title" />
                </span>
            </div>
            <div v-once v-if="showHorizontalLine" class="py-2 w-full flex justify-center">
                <div class="hr" :class="getHrClasses()" />
            </div>

            <div :class="getBodyClasses()">
                <slot />
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
// imports
    import { ref, onBeforeMount, type Ref, useSlots } from 'vue'
    const slots = useSlots()

    // props
    const props = defineProps({
        roundedClass: {
            type: [String, Object],
            default: 'rounded-lg'
        },
        border: {
            type: String,
            default: 'default',
            validator(value: string) {
                return [
                    'default',
                    'danger',
                    'success',
                    'warning',
                    'white',
                    'transparent',
                    'pink',
                    'light-green',
                    'dark-green'
                ].includes(value)
            }
        },
        background: {
            type: String,
            default: 'default',
            validator(value: string) {
                return [
                    'default',
                    'light-green',
                    'dark-green',
                    'dark-green-2',
                    'dark-green-extra',
                    'transparent-light-green',
                    'transparent-white',
                    'dark-green-reverse',
                    'white',
                    'gray',
                    'black',
                    'blue-light'
                ].includes(value)
            }
        },
        shadow: {
            type: Boolean,
            default: false
        },
        showHorizontalLine: {
            type: Boolean,
            default: false
        },
        bgImage: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        bodyClass: {
            type: String,
            default: ''
        },
        cardClass: {
            type: String,
            default: 'p-3 md:p-5'
        },
        hrClass: {
            type: String,
            default: ''
        },
        hrColor: {
            type: String,
            default: 'default',
            validator(value: string) {
                return ['default'].includes(value)
            }
        },
        titleCenter: {
            type: Boolean,
            default: true
        },
        noCardPadding: {
            type: Boolean,
            default: false
        }
    })

    // variables
    const cardName: Ref<string> = ref('')

    // methods
    const getBorderClasses = () => {
        switch (props.border) {
        case 'danger':
            return 'border-danger'
        case 'success':
            return 'border-success'
        case 'warning':
            return 'border-warning'
        case 'white':
            return 'border-white'
        case 'transparent':
            return 'border-transparent'
        case 'pink':
            return 'border-pink'
        case 'light-green':
            return 'border-light-green'
        case 'dark-green':
            return 'border-dark-green'
        }
    }

    const getBackgroundClasses = () => {
        switch (props.background) {
        case 'white':
            return 'bg-white'
        case 'light-green':
            return 'bg-light-green'
        case 'dark-green':
            return 'bg-dark-green'
        case 'dark-green-2':
            return 'bg-dark-green-2'
        case 'dark-green-reverse':
            return 'bg-dark-green-reverse'
        case 'dark-green-extra':
            return 'bg-dark-green-extra'
        case 'transparent-light-green':
            return 'bg-transparent-light-green'
        case 'transparent-white':
            return 'bg-transparent-white'
        case 'gray':
            return 'bg-gray'
        case 'black':
            return 'bg-black'
        case 'blue-light':
            return 'bg-blue-light'
        }
    }
    const getHrClasses = () => {
        const classes = []

        classes.push(props.hrClass)

        return classes
    }

    const getCardClasses = () => {
        const classes = [props.roundedClass, getBorderClasses(), getBackgroundClasses(), props.cardClass]

        if (props.shadow) classes.push('card-shadow')
        if (props.bgImage) classes.push('bg-image')
        if (props.noCardPadding) classes.push('!p-0')

        return classes
    }

    const getStyles = () => {
        const styles = {}

        return styles
    }

    const getBodyClasses = () => {
        const classes = []
        if (props.bodyClass) classes.push(props.bodyClass)
        else classes.push('flex flex-col')
        return classes
    }

    // life hooks
    onBeforeMount(() => {
        if (props.name) cardName.value = props.name
        else cardName.value = 'card'
    })
</script>
<style scoped>
.card {
  background-color: #fafafa;
  border: 2px solid #ccdddc;
}
.bg-white {
  background: #FFFFFF;
}
.bg-light-green {
  background: rgba(238, 241, 241, 1);
}
.bg-dark-green {
  background: linear-gradient(0deg, #ccdddc, #ccdddc),
    linear-gradient(0deg, rgba(204, 221, 220, 0.54), rgba(204, 221, 220, 0.54));
}
.bg-dark-green-2 {
  background: #317B75
}
.bg-dark-green-reverse {
  background: linear-gradient(0deg, rgba(204, 221, 220, 0.5), rgba(204, 221, 220, 0.5));
}
.bg-dark-green-extra {
  background: #005C55;
}
.bg-transparent-light-green {
  background-color: #ccdddc52;
}
.bg-transparent-white {
  background-color: #ffffff80;
}
.bg-gray {
  background-color: #EDEDED;
}
.bg-black {
  background-color: #262B37;
  color: white;
}
.bg-blue-light {
  background-color: #BBDFE3;
  color: black;
}
.card-image {
  width: 100%;
  filter: drop-shadow(10px 4px 11px rgba(0, 0, 0, 0.25));
}

.border-white {
  border: 2px solid #ffffff;
}

.border-danger {
  border: 2px solid #ed1a39;
}

.border-success {
  border: 2px solid #3dbdb0;
}

.border-warning {
  border: 2px solid #d78128;
}

.border-transparent {
  border: none;
}

.border-pink {
  border: 1px solid rgba(237, 26, 57, 0.38);
}

.border-light-green {
  border: 1px solid rgba(61, 189, 176, 0.34);
}
.border-light-green {
  border: 1px solid #005c55;
}
.card-shadow {
  box-shadow: 10px 11px 21px rgba(0, 0, 0, 0.1);
}
.hr {
  background-color: #005c55;
  width: 100%;
  height: 2px;
  margin: 0 auto;
}
</style>
