<template>
    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 1.5C0 0.671573 0.671573 0 1.5 0H17.5C18.3284 0 19 0.671573 19 1.5C19 2.32843 18.3284 3 17.5 3H1.5C0.671573 3 0 2.32843 0 1.5ZM0 8.5C0 7.67157 0.671573 7 1.5 7H17.5C18.3284 7 19 7.67157 19 8.5C19 9.32843 18.3284 10 17.5 10H1.5C0.671573 10 0 9.32843 0 8.5ZM1.5 14C0.671573 14 0 14.6716 0 15.5C0 16.3284 0.671573 17 1.5 17H17.5C18.3284 17 19 16.3284 19 15.5C19 14.6716 18.3284 14 17.5 14H1.5Z"
            fill="currentColor"
        />
    </svg>
</template>
