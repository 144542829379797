<template>
    <Card
        background="dark-green"
        class="rounded-[24px] h-fit mb-[40px]"
        no-card-padding
        body-class="flex flex-col h-full"
    >
        <div class="flex flex-col pt-6 gap-2 px-4 mb-auto">
            <div class="text-center flex flex-col">
                <span class="font-semibold text-2xl md:text-4xl uppercase">{{ nftName }}</span>
                <span
                    class="text-xl md:text-3xl pt-2"
                    v-if="![NFTInfoCardState.Presentation,NFTInfoCardState.NoNFT].includes(currentState)"
                >{{$t('general.nft_id')}}: {{ NFTDataComputed ? NFTDataComputed.id : '' }}</span
                >
            </div>

            <div class="relative" :class="{'no-nft': currentState === NFTInfoCardState.NoNFT}">
                <div
                    class="nft-image rounded-lg px-2 mx-auto"
                    :class="[
                        currentState === NFTInfoCardState.Presentation
                            ? 'pb-8'
                            : 'pb-14 sm:pb-20 md:pb-14'
                    ]"
                    :style="`border: 3px solid var(--nft-level-color-${NFTDataComputed ? NFTDataComputed.level : 0})`"
                >
                    <div class="mx-auto max-w-xs sm:max-w-sm min-h-[180px]">
                        <img :src="imageUrl" class="mx-auto max-w-[200px] min-h-[180px] w-full md:w-full py-2"
                             :class="{'opacity-70': currentState === NFTInfoCardState.NoNFT}" alt="MetFi NFT images"
                        />
                        <LevelScale
                            class="max-[360px]:px-1 px-6 lg:px-1 xl:px-6 2xl:px-12"
                            :level="NFTDataComputed ? NFTDataComputed.level : 0"
                        />
                    </div>
                </div>
                <!--                <div
                    class="absolute -mt-8 px-4 w-full"
                    v-if="currentState !== NFTInfoCardState.Presentation"
                >
                    <RewardBadge/>
                </div>-->
            </div>

            <div
                class="flex items-center max-[300px]:justify-between justify-center gap-2 w-full mt-4 relative pb-4"
                v-if="![NFTInfoCardState.Presentation, NFTInfoCardState.NoNFT].includes(currentState) && NFTDataComputed"
            >
                <span class="font-semibold text-xl md:text-2xl lg:text-2xl break-all">
                    {{ NFTDataComputed.referral_link }}</span
                >
                <CopyComponent :text="getRefLink(NFTDataComputed.referral_link)" class="right-0 min-[300px]:absolute"/>
            </div>

            <template
                v-if="
                    [NFTInfoCardState.FullInfo, NFTInfoCardState.Presentation].includes(
                        currentState
                    )
                "
            >
                <Card
                    border="white"
                    background="dark-green-reverse"
                    card-class="p-2"
                    body-class="flex flex-wrap items-center justify-between gap-x-2 lg:p-2"
                >
                    <span class="text-lg lg:text-xl !leading-5 lg:!leading-6">{{$t('general.nft')}} {{$t('general.level')}}:</span>
                    <span class="font-bold text-2xl lg:text-4xl flex items-center gap-0.5"
                    >{{ NFTDataComputed ? NFTDataComputed.level + 1 : 0 }}/11</span
                    >
                </Card>

            </template>
            <div v-if="currentState === NFTInfoCardState.Minimal" class=""/>

            <div
                v-if="currentState === NFTInfoCardState.Presentation"
                class="flex justify-center pt-14"
            />

            <div
                v-if="currentState === NFTInfoCardState.NoNFT"
                class="flex justify-center py-8"
            >
                <a href="https://app.metfi.io/" target="_blank">
                    <Button>{{$t('general.become_metfi_member')}}</Button>
                </a>
            </div>
        </div>

        <CardMenu v-if="showMenu" @menu-toggled="onMenuChanged" :is-menu-open="isMenuOpen"/>
    </Card>
</template>
<script lang="ts" setup>
    import {computed, onMounted, type PropType, type Ref, ref} from 'vue'
    import LevelScale from '@/components/LevelScale.vue'
    import Card from '@/components/Card.vue'
    import Button from '@/components/Button.vue'
    import {NFTInfoCardState} from '@/enums/NFTInfo'
    import CardMenu from '@/components/NFT/NFTInfoCard/components/CardMenu.vue'
    import {useAppStore} from '@/stores/app'
    import type {NFT} from '@/types/Account'
    import CopyComponent from '@/components/CopyComponent.vue'

    const appStore = useAppStore()

    const props = defineProps({
        nftData: {
            type: Object as PropType<NFT|undefined>
        },
        showMenu: {
            type: Boolean,
            default: false
        },
        currentState: {
            type: Number,
            default: 2
        }
    })
    const isMenuOpen : Ref<boolean> = ref(true)
    // 1 - full info
    // 2 - minimal
    // 3 - presentation
    // 4 - no NFT

    // computed
    const NFTDataComputed = computed(() => {
        if (props.nftData) return props.nftData
        if (appStore.getSelectedNFT()) return appStore.getSelectedNFT() as NFT
        return undefined
    })

    const getRefLink = (referral : string) => {
        return `https://app.metfi.io/ref/${referral}`
    }
    const onMenuChanged = () => {
        isMenuOpen.value = !isMenuOpen.value
    }

    const imageUrl = computed(() => {
        let level = 0

        if (NFTDataComputed.value) {
            level = NFTDataComputed.value?.level
        }

        switch (level) {
        case 0:
            return new URL('@/assets/images/nfts/level0.webp', import.meta.url).href
        case 1:
            return new URL('@/assets/images/nfts/level1.webp', import.meta.url).href
        case 2:
            return new URL('@/assets/images/nfts/level2.webp', import.meta.url).href
        case 3:
            return new URL('@/assets/images/nfts/level3.webp', import.meta.url).href
        case 4:
            return new URL('@/assets/images/nfts/level4.webp', import.meta.url).href
        case 5:
            return new URL('@/assets/images/nfts/level5.webp', import.meta.url).href
        case 6:
            return new URL('@/assets/images/nfts/level6.webp', import.meta.url).href
        case 7:
            return new URL('@/assets/images/nfts/level7.webp', import.meta.url).href
        case 8:
            return new URL('@/assets/images/nfts/level8.webp', import.meta.url).href
        case 9:
            return new URL('@/assets/images/nfts/level9.webp', import.meta.url).href
        case 10:
            return new URL('@/assets/images/nfts/level10.webp', import.meta.url).href
        }

        return ''
    })

    const nftName = computed(() => {
        let level = 0
        if (NFTDataComputed.value) {
            level = NFTDataComputed.value?.level
        }

        switch (level) {
        case 0:
            return 'PLANKTON'
        case 1:
            return 'SHRIMP'
        case 2:
            return 'CRAB'
        case 3:
            return 'OCTOPUS'
        case 4:
            return 'FISH'
        case 5:
            return 'DOLPHIN'
        case 6:
            return 'SHARK'
        case 7:
            return 'ORCA'
        case 8:
            return 'Whale'
        case 9:
            return 'WHALE SHARK'
        case 10:
            return 'HUMPBACK'
        }

        return ''
    })

    onMounted(() => {
    })
</script>

<style scoped>
.nft-image {
  background-color: white;
}
.no-nft {
  filter: grayscale(1);
}
</style>
