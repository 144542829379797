import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(localizedFormat)

const formatDate = (
  date: Date | number | string,
  showTime: boolean = true,
  showSeconds: boolean = false
) => {
  const formatToken = showTime ? `YYYY/MM/DD HH:mm${showSeconds ? ':ss' : ''}` : 'YYYY/MM/DD'
  return dayjs(date).format(formatToken)
}

export default dayjs
export { formatDate }
