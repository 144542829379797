<template>
    <Card background="white" border="white" :title-center="false" no-card-padding>
        <div class="p-3 lg:p-5">
            <h1 class="text-2xl"><span class="font-bold">{{$t('staking.title_1')}}</span> {{$t('staking.title_2')}}</h1>
            <hr class="hr my-4"/>
            <StakingCycles v-if="stakingData" :current-cycle="stakingData.current_cycle" :new-cycle="stakingData.new_cycle" class="mt-4"/>
            <hr class="hr my-8"/>
            <MyStaking />
        </div>
        <MyMoguls class="mt-4" @load-data="loadData" :staking-data="stakingData"/>
        <div class="p-3 lg:p-5">
            <p class="text-base lg:text-lg text-black text-center font-semibold mt-2">{{$t('staking.buy_mogul_text', [formatNumber(MOGULBalance.mogul_to_whole_number)])}}</p>
            <div class="flex justify-center mt-4">
                <a href="https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x0c9bb15b32334bDAA7Ad319FA356Dd3E8e184564" target="_blank">
                    <Button variant="light-blue" font-size="!text-xl lg:!text-2xl">{{$t('general.buy_now')}}</Button>
                </a>
            </div>
        </div>
    </Card>
</template>

<script setup lang="ts">
    import Card from '@/components/Card.vue'
    import StakingCycles from '@/components/staking/components/StakingCycles.vue'
    import MyStaking from '@/components/staking/components/MyStaking.vue'
    import MyMoguls from '@/components/staking/components/MyMoguls.vue'
    import {computed, onMounted, type Ref, ref} from 'vue'
    import {useAppStore} from '@/stores/app'
    import type {AxiosResponse} from 'axios'
    import axios from '@/libs/axios'
    import {captureException} from '@sentry/vue'
    import {toast} from 'vue3-toastify'
    import type {StakingData} from '@/types/Staking'
    import {useI18n} from 'vue-i18n'
    import type {WebConfig} from '@/types/Common'
    import {useChainStore} from '@/stores/chain'
    import {formatNumber} from '@/libs/helpers'
    import Button from '@/components/Button.vue'

    const appStore = useAppStore()
    const chainStore = useChainStore()

    const stakingData : Ref<StakingData|undefined> = ref(undefined)
    const t = useI18n().t
    const webConfig : Ref<WebConfig|undefined> = ref(undefined)

    const MOGULBalance = computed(() => {
        return appStore.getMOGULBalance()
    })

    const loadData = async() => {
        try {
            appStore.startLoading()
            const res : AxiosResponse<StakingData> = await axios.get('/api/v1/moguls/staking')
            stakingData.value = res.data
        } catch (e) {
            captureException(e)
            toast.error(t('errors.there_was_an_error_placeholder', {'error_message': t('errors.loading_data')}))
        } finally {
            appStore.stopLoading()
        }
    }

    onMounted(async() => {
        await loadData()
        webConfig.value = await chainStore.getWebConfig()
    })
</script>

<style scoped>

</style>
