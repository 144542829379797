import type {AxiosResponse} from 'axios'
import {useAppStore} from '@/stores/app'
import axios from '@/libs/axios'
import {captureException} from '@sentry/vue'

interface SpectatorLoginRequest {
    nft_id: number,
    username: string,
    wallet_address: string
}

const checkSpectatorLogin = async() => {

    const spectatorLoginPayload : SpectatorLoginRequest = {
        nft_id: 0,
        username: '',
        wallet_address: ''
    }

    const urlSearchParams = new URLSearchParams(window.location.search)
    let spectatorLogin = true

    if (urlSearchParams.get('devCustomAddress')) {
        spectatorLoginPayload.wallet_address = urlSearchParams.get('devCustomAddress')!
        urlSearchParams.delete('devCustomAddress')
        console.log('devCustomAddress', spectatorLoginPayload.wallet_address)
    } else if (urlSearchParams.get('devCustomUser')) {
        spectatorLoginPayload.username = urlSearchParams.get('devCustomUser')!
        urlSearchParams.delete('devCustomUser', spectatorLoginPayload.username)
        console.log('devCustomAddress')
    } else if (urlSearchParams.get('devCustomNFT')) {
        spectatorLoginPayload.nft_id = Number(urlSearchParams.get('devCustomNFT')!)
        urlSearchParams.delete('devCustomNFT')
        console.log('devCustomNFT', spectatorLoginPayload.nft_id)
    } else {
        spectatorLogin = false
    }

    if (spectatorLogin) {
        try {
            const appStore = useAppStore()
            await appStore.disconnect()
            const response: AxiosResponse<string> = await axios.post('/api/v1/moguls/spectator_login', spectatorLoginPayload)
            await appStore.setLogin(response.data)
        } catch (e) {
            captureException(e)
        }
        const paths = window.location.href.split('?')
        if (paths.length === 0) {
            location.reload()
        } else {
            const newURL = `${paths[0]}?${urlSearchParams.toString()}`
            window.location.assign(newURL)
        }

    }
}

export {checkSpectatorLogin}
