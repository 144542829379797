<template>
    <Card id="my-moguls" background="black" border="transparent" no-card-padding>
        <div class="py-4 lg:py-8">
            <h1 class="text-2xl text-blue-light font-bold text-center">{{$t('my_marine_moguls.my_marine_moguls')}}:</h1>
            <div class="p-4 metamask-alert m-4 rounded-lg text-center" v-if="showMetaMaskVersionNotWorkingMessage">
                <i18n-t keypath="metamask_version_too_low_message" tag="label">
                    <span class="font-bold">Google Chrome</span>
                    <span class="font-bold">MetaMask v11.15.4</span>
                </i18n-t>
            </div>

            <div class="bg-green-extra-light-2/50 rounded-lg pt-6 pb-12 px-4 mt-6">
                <p class="text-base lg:text-lg text-blue-light font-semibold mb-12 sm:mb-8">{{ $t('my_marine_moguls.in_wallet') }}</p>
                <template v-if="marineMogulsInWallet && marineMogulsInWallet.length > 0">
                    <Carousel class="relative" :breakpoints="carouselSettingsInWallet" :items="marineMogulsInWallet"
                              @sufficient-slides-for-breakpoint="onSufficientSlidesForBreakpointChangedInWallet"
                              ref="inWalletMOGULCarousel">
                        <template #slide="{item}: {item: Mogul}">
                            <MogulCard :data="item" has-button @click="onStakeMogulClicked(item.nft_id)">
                                <template #button-icon v-if="!isLastCycleOver">
                                    <IconChevronsDown/>
                                </template>
                            </MogulCard>
                        </template>
                        <template #pagination="{slider}: {slider: KeenSliderInstance}" v-if="showInWalletCarouselArrows">
                            <div class="absolute -top-12 sm:-top-16 w-full flex justify-center gap-8 pb-4">
                                <IconChevronsLeft :size="appStore.isMobile() ? 35 : 50" class="text-green-extra-light-2 hover:text-blue-light transition-all cursor-pointer" @click="slider.prev()"/>
                                <IconChevronsRight :size="appStore.isMobile() ? 35 : 50" class="text-green-extra-light-2 hover:text-blue-light transition-all cursor-pointer" @click="slider.next()"/>
                            </div>
                        </template>
                    </Carousel>
                </template>
                <div v-else class="min-h-[145px] flex items-center justify-center">
                    <h1>{{ $t('my_marine_moguls.no_moguls_in_wallet_text') }}</h1>
                </div>
            </div>
            <div class="py-10 px-4 relative">
                <p class="text-base lg:text-lg text-blue-light font-semibold mb-8">{{$t('my_marine_moguls.in_process')}}</p>

                <template v-if="marineMogulsInProcess && marineMogulsInProcess.length > 0">
                    <div class="relative mb-6">
                        <Carousel class="max-[530px]:px-0 px-16 xl:px-28 2xl:px-40" :breakpoints="carouselSettingsInProcess" :items="marineMogulsInProcess"
                                  @sufficient-slides-for-breakpoint="onSufficientSlidesForBreakpointChangedInProcess" ref="inProcessMOGULCarousel">
                            <template #slide="{item}: {item: Mogul}">
                                <MogulCard :data="item" class="h-full">
                                    <template #data>
                                        <div class="flex flex-col gap-3 items-center px-2 mt-auto h-full justify-between text-center">
                                            <template v-if="StakingStatus.Warmup  === item.staking_status">
                                                <p class="text-lg font-semibold text-white leading-5">
                                                    {{$t('my_marine_moguls.entering_staking_in')}} {{  timeUntilStartOfFirstCycle }}
                                                </p>
                                                <Button class="!py-2 !min-w-12 mb-3" variant="light-blue" @click="onUnstakeClicked(item.nft_id)">
                                                    <span class="text-xl text-black font-semibold uppercase">{{$t('airdrop_claims.unstake')}}</span>
                                                </Button>
                                            </template>
                                            <template v-else-if="StakingStatus.UnstakeAvailable  === item.staking_status">
                                                <p class="text-lg font-semibold text-blue-dark-2 leading-5">
                                                    {{$t('my_marine_moguls.unstake_available_for')}} {{ timeRemaining }}
                                                </p>
                                                <Button class="!py-2 !min-w-12 mb-3" variant="light-blue" @click="onUnstakeClicked(item.nft_id)">
                                                    <span class="text-xl text-black font-semibold uppercase">{{$t('airdrop_claims.unstake')}}</span>
                                                </Button>
                                            </template>
                                            <template v-else-if="item.staking_status === StakingStatus.EnteringStaking">
                                                <p class="text-lg font-semibold text-white mb-3 leading-5">
                                                    {{$t('my_marine_moguls.entering_staking_in')}} {{ timeRemaining }}
                                                </p>
                                            </template>
                                            <template v-else-if="item.staking_status === StakingStatus.EnteringUnstaking">
                                                <p class="text-lg font-semibold text-blue-dark-2 leading-5">
                                                    {{$t('my_marine_moguls.unstake_in')}} {{ timeRemainingUntilUnstakeAvailable }}
                                                </p>
                                                <Button class="!py-2 !min-w-12 mb-3" variant="light-blue" @click="onCancelUnstakeClicked(item.nft_id)">
                                                    <span class="text-xl text-black font-semibold uppercase leading-4">{{$t('my_marine_moguls.cancel_unstake')}}</span>
                                                </Button>
                                            </template>
                                            <template v-else-if="item.staking_status === StakingStatus.InUnstaking">
                                                <p class="text-lg font-semibold text-blue-dark-2 leading-5">
                                                    {{$t('my_marine_moguls.unstake_in')}} {{ timeRemaining }}
                                                </p>
                                                <Button class="!py-2 !min-w-12 mb-3" variant="light-blue" @click="onCancelUnstakeClicked(item.nft_id)">
                                                    <span class="text-xl text-black font-semibold uppercase leading-4">{{$t('my_marine_moguls.cancel_unstake')}}</span>
                                                </Button>
                                            </template>
                                        </div>
                                    </template>
                                </MogulCard>
                            </template>
                            <template #pagination="{slider}: {slider: KeenSliderInstance}" v-if="showInProcessCarouselArrows">
                                <div class="max-[530px]:relative absolute top-[50%] left-0 -translate-y-[50%] w-full flex max-[530px]:justify-center justify-between gap-8 max-[530px]:mt-8">
                                    <IconChevronsLeft :size="appStore.isMobile() ? 35 : 50" class="text-green-extra-light-2 hover:text-blue-light transition-all cursor-pointer" @click="slider.prev()"/>
                                    <IconChevronsRight :size="appStore.isMobile() ? 35 : 50" class="text-green-extra-light-2 hover:text-blue-light transition-all cursor-pointer" @click="slider.next()"/>
                                </div>
                            </template>
                        </Carousel>
                    </div>
                </template>
                <!--              TODO - when clicking Stake All and moguls go from in wallet to in process, arrows for left and right are not displayed -->
                <div v-else class="min-h-[155px] flex items-center justify-center">
                    <h1>{{ $t('my_marine_moguls.no_moguls_in_process_text') }}</h1>
                </div>
                <Button class="big-button absolute max-[260px]:-top-[22px] -top-[30px] left-[50%] -translate-x-[50%]" variant="light-blue" size="small" @click="onStakeAllClicked"
                        :disabled="!isStakeEnabled">
                    <span class="flex items-center gap-1">
                        <IconChevronsUp class="big-button-icon rotate-180" :size="bigButtonSize"
                                        :class="{'text-dark-gray': !isStakeEnabled, 'text-blue-dark': isStakeEnabled}"/>
                        <span class="text-xl font-semibold uppercase"
                              :class="{'text-dark-gray': !isStakeEnabled, 'text-black': isStakeEnabled}">{{ $t('my_marine_moguls.stake') }}</span>
                        <IconChevronsUp class="big-button-icon rotate-180" :size="bigButtonSize"
                                        :class="{'text-dark-gray': !isStakeEnabled, 'text-blue-dark': isStakeEnabled}"/>
                    </span>
                </Button>
                <Button class="big-button absolute max-[260px]:-bottom-[22px] -bottom-[30px] left-[50%] -translate-x-[50%]"
                        variant="light-blue" size="small" @click="onRequestUnstakeAllClicked"
                        :disabled="!isUnstakeEnabled">
                    <span class="flex items-center gap-1">
                        <IconChevronsUp class="big-button-icon" :size="bigButtonSize"
                                        :class="{'text-dark-gray': !isUnstakeEnabled, 'text-blue-dark': isUnstakeEnabled}"/>
                        <span class="text-xl font-semibold uppercase"
                              :class="{'text-dark-gray': !isUnstakeEnabled, 'text-black': isUnstakeEnabled}">{{ $t('my_marine_moguls.unstake') }}</span>
                        <IconChevronsUp class="big-button-icon" :size="bigButtonSize"
                                        :class="{'text-dark-gray': !isUnstakeEnabled, 'text-blue-dark': isUnstakeEnabled}"/>
                    </span>
                </Button>
            </div>
            <div class="bg-green-extra-light-2/50 rounded-lg p-4">
                <p class="text-base lg:text-lg text-blue-light font-semibold mt-4 mb-8">{{ $t('my_marine_moguls.staked') }}</p>
                <template v-if="marineMogulsStaked && marineMogulsStaked.length > 0">
                    <Carousel :breakpoints="carouselSettingsStaked" :items="marineMogulsStaked" ref="stakedMOGULCarousel"
                              @sufficient-slides-for-breakpoint="onSufficientSlidesForBreakpointChangedStaked">
                        <template #slide="{item}: {item: Mogul}">
                            <MogulCard :data="item" has-button @click="onRequestUnstakeMogulClicked(item.nft_id)">
                                <template #button-icon v-if="!isLastCycleOver">
                                    <IconChevronsUp/>
                                </template>
                            </MogulCard>
                        </template>
                        <template #pagination="{slider}: {slider: KeenSliderInstance}" v-if="showStakedCarouselArrows">
                            <div class="w-full flex justify-center gap-8 mt-8">
                                <IconChevronsLeft :size="appStore.isMobile() ? 35 : 50" class="text-green-extra-light-2 hover:text-blue-light transition-all cursor-pointer" @click="slider.prev()"/>
                                <IconChevronsRight :size="appStore.isMobile() ? 35 : 50" class="text-green-extra-light-2 hover:text-blue-light transition-all cursor-pointer" @click="slider.next()"/>
                            </div>
                        </template>
                    </Carousel>
                </template>
                <div v-else class="min-h-[145px] flex items-center justify-center">
                    <h1>{{ $t('my_marine_moguls.no_moguls_staked_text') }}</h1>
                </div>
            </div>
            <div class="p-4 metamask-alert mx-4 mt-4 mb-1 lg:-mb-4 rounded-lg text-center unstaking-notification" v-if="marineMogulsStaked && marineMogulsStaked.length > 0">
                {{$t('staking.unstaking_notification')}}
            </div>
        </div>
    </Card>
</template>

<script setup lang="ts">
    import Card from '@/components/Card.vue'
    import MogulCard from '@/components/marineMoguls/components/MogulCard.vue'
    import Carousel from '@/components/Carousel.vue'
    import {IconChevronsLeft, IconChevronsRight, IconChevronsUp} from '@tabler/icons-vue'
    import {computed, inject, onMounted, type PropType, type Ref, ref, watch} from 'vue'
    import Button from '@/components/Button.vue'
    import IconChevronsDown from '@/assets/icons/IconChevronsDown.vue'
    import {useAppStore} from '@/stores/app'
    import type {Mogul} from '@/types/Mogul'
    import type {KeenSliderInstance} from 'keen-slider/vue.es'
    import {captureException} from '@sentry/vue'
    import {toast} from 'vue3-toastify'
    import {useChainStore} from '@/stores/chain'
    import {useI18n} from 'vue-i18n'
    import {StakingStatus} from '@/enums/mogul'
    import type {StakingData} from '@/types/Staking'
    import {formatCountdown, processContractRunnerError} from '@/libs/helpers'

    const appStore = useAppStore()
    const chainStore = useChainStore()
    const t = useI18n().t
    const emit = defineEmits<{(e: 'load-data'): void }>()
    const stakedMOGULCarousel = ref()
    const inWalletMOGULCarousel = ref()
    const inProcessMOGULCarousel = ref()
    const selectedMogulInModal : Ref<number> = inject('mogul-selected-in-modal') as Ref<number>

    const props = defineProps({
        stakingData: {
            type: Object as PropType<StakingData|undefined>,
            required: false
        }
    })

    const carouselSettingsInWallet = {
        '(min-width: 330px)': {
            slides: {perView: 2, spacing: 8}
        },
        '(min-width: 450px)': {
            slides: {perView: 3, spacing: 10}
        },
        '(min-width: 640px)': {
            slides: {perView: 4, spacing: 5}
        },
        '(min-width: 1536px)': {
            slides: {perView: 5, spacing: 8}
        }
    }

    const carouselSettingsInProcess = {
        '(min-width: 330px)': {
            slides: {perView: 2, spacing: 10}
        },
        '(min-width: 1024px)': {
            slides: {perView: 3, spacing: 8}
        }
    }

    const carouselSettingsStaked = {
        '(min-width: 330px)': {
            slides: {perView: 2, spacing: 8}
        },
        '(min-width: 450px)': {
            slides: {perView: 3, spacing: 10}
        },
        '(min-width: 640px)': {
            slides: {perView: 4, spacing: 5}
        },
        '(min-width: 1536px)': {
            slides: {perView: 5, spacing: 8}
        }
    }
    const isStakingContractApproved : Ref<Boolean> = ref(false)
    const showInWalletCarouselArrows : Ref<boolean> = ref(true)
    const showInProcessCarouselArrows : Ref<boolean> = ref(true)
    const showStakedCarouselArrows : Ref<boolean> = ref(true)
    const showMetaMaskVersionNotWorkingMessage : Ref<boolean> = ref(false)

    const marineMogulsInWallet = computed(() => {
        return appStore.getMarineMoguls().filter(NFT => NFT.staking_status === StakingStatus.InWallet) as Array<Mogul>
    })
    const marineMogulsStaked = computed(() => {
        return appStore.getMarineMoguls().filter(NFT => NFT.staking_status === StakingStatus.Staked) as Array<Mogul>
    })

    const inProcessStatuses : Array<string> = [StakingStatus.EnteringStaking, StakingStatus.InUnstaking, StakingStatus.UnstakeAvailable, StakingStatus.Warmup, StakingStatus.EnteringUnstaking]
    const marineMogulsInProcess = computed(() => {
        return appStore.getMarineMoguls().filter(NFT => inProcessStatuses.includes(NFT.staking_status)) as Array<Mogul>
    })
    const timeRemaining = computed(() => {
        if (!props.stakingData || !props.stakingData.new_cycle) return ''
        const diff = props.stakingData.new_cycle.starting_timestamp_unix - appStore.currentTimestamp
        const {fullText} = formatCountdown(diff, {showSeconds: false, showMinutes: false})
        return fullText
    })
    const timeRemainingUntilUnstakeAvailable = computed(() => {
        if (!props.stakingData || !props.stakingData.new_cycle) return ''
        const diff = props.stakingData.new_cycle.starting_timestamp_unix + (4 * 7 * 24 * 60 * 60) - appStore.currentTimestamp
        const {fullText} = formatCountdown(diff, {showSeconds: false, showMinutes: false})
        return fullText
    })
    const timeUntilStartOfFirstCycle = computed(() => {
        const diff = 1718064000 - appStore.currentTimestamp // Tue Jun 11 2024 00:00:00 GMT+0000
        const {fullText} = formatCountdown(diff, {showSeconds: false, showMinutes: false})
        return fullText
    })
    const isLastCycleOver = computed(() => {
        return appStore.currentTimestamp > 1749513600 // Tue Jun 10 2025 00:00:00 GMT+0000
    })
    const bigButtonSize = computed(() => {
        return appStore.windowWidth > 260 ? 40 : 30
    })
    const isUnstakeEnabled = computed(() => {
        return marineMogulsStaked.value && marineMogulsStaked.value.length > 0 && !isLastCycleOver.value
    })
    const isStakeEnabled = computed(() => {
        return marineMogulsInWallet.value && marineMogulsInWallet.value.length > 0 && !isLastCycleOver.value
    })

    // watch
    watch(() => marineMogulsStaked.value && marineMogulsStaked.value.length, (newVal, oldVal) => {
        if (newVal === oldVal) return
        if (stakedMOGULCarousel.value) stakedMOGULCarousel.value!.forceUpdateSlider()
    })
    watch(() => marineMogulsInProcess.value && marineMogulsInProcess.value.length, (newVal, oldVal) => {
        if (newVal === oldVal) return
        if (inProcessMOGULCarousel.value) inProcessMOGULCarousel.value!.forceUpdateSlider()
    })
    watch(() => marineMogulsInWallet.value && marineMogulsInWallet.value.length, (newVal, oldVal) => {
        if (newVal === oldVal) return
        if (inWalletMOGULCarousel.value) inWalletMOGULCarousel.value!.forceUpdateSlider()
    })
    watch(() => selectedMogulInModal.value, (newVal) => {
        const inWalletIndex = marineMogulsInWallet.value.findIndex(m => m.nft_id === newVal)
        if (inWalletIndex !== -1 && inWalletMOGULCarousel.value) inWalletMOGULCarousel.value!.moveToIndex(inWalletIndex)

        const inProgressIndex = marineMogulsInProcess.value.findIndex(m => m.nft_id === newVal)
        if (inProgressIndex !== -1 && inProcessMOGULCarousel.value) inProcessMOGULCarousel.value!.moveToIndex(inProgressIndex)

        const inStakingIndex = marineMogulsStaked.value.findIndex(m => m.nft_id === newVal)
        if (inStakingIndex !== -1 && stakedMOGULCarousel.value) stakedMOGULCarousel.value!.moveToIndex(inStakingIndex)
    })

    // functions

    const onSufficientSlidesForBreakpointChangedInWallet = (isSufficient : boolean) => {
        showInWalletCarouselArrows.value = isSufficient
    }
    const onSufficientSlidesForBreakpointChangedInProcess = (isSufficient : boolean) => {
        showInProcessCarouselArrows.value = isSufficient
    }
    const onSufficientSlidesForBreakpointChangedStaked = (isSufficient : boolean) => {
        showStakedCarouselArrows.value = isSufficient
    }

    const getMetamaskVersion = async() => {

        if (!window.ethereum) return

        const res : string = await window.ethereum.request({
            'method': 'web3_clientVersion',
            'params': []
        })

        if (res.includes('Mobile')) {

        } else {
            const metamaskVersionSplit = res.split('/') // MetaMask/v11.12.4
            if (!metamaskVersionSplit.length) return // ['Metamask', 'v11.12.4']
            const versions = metamaskVersionSplit[1].split('.') // [ "v11", "12", "4" ]
            if (versions.length > 2 && versions[0] === 'v11' && Number(versions[1]) <= 12) {
                showMetaMaskVersionNotWorkingMessage.value = true
            }
        }
    }
    const checkIfApproved = async() => {
        try {
            appStore.startLoading()
            const mogulsContract = await chainStore.getMarineMogulsInterface()
            const userData = appStore.getLoggedInUser()
            const webConfig = await chainStore.getWebConfig()

            isStakingContractApproved.value = await mogulsContract
                .isApprovedForAll(userData.WalletAddress, webConfig.marine_moguls_staking)
        } catch (e) {
            captureException(e)
            toast.error(t('errors.there_was_an_error_placeholder', {'error_message': t('errors.stake_error.checking_approval')}))
        } finally {
            appStore.stopLoading()
        }
    }

    const setApproveForAll = async() => {
        try {
            appStore.startLoading()
            const mogulsContract = await chainStore.getMarineMogulsInterface()
            const signer = await chainStore.getSigner()
            const webConfig = await chainStore.getWebConfig()

            const tx = await mogulsContract
                .connect(signer)
                .setApprovalForAll(webConfig.marine_moguls_staking, true)
            await tx.wait(3)
            await checkIfApproved()
        } catch (e) {
            processContractRunnerError(e)
            captureException(e)
            toast.error(t('errors.there_was_an_error_placeholder', {'error_message': t('errors.stake_error.approving')}))
        } finally {
            appStore.stopLoading()
        }
    }

    const onStakeAllClicked = async() => {
        try {
            appStore.startLoading()

            if (!isStakingContractApproved.value) await setApproveForAll()

            const signer = await chainStore.getSigner()
            const stakingContract = await chainStore.getMarineMogulsStakingInterface()

            const tx = await stakingContract
                .connect(signer)
                .stakeAll()

            await tx.wait(3)
            try {
                (window as any)._paq.push(['trackEvent', 'Stake', 'Stake All'])
            } catch (e) {}
            await appStore.loadMogulNFTs()
            emit('load-data')
        } catch (e) {
            processContractRunnerError(e)
            captureException(e)
            toast.error(t('errors.there_was_an_error_placeholder', {'error_message': t('errors.stake_error.staking')}))
        } finally {
            appStore.stopLoading()
        }
    }

    const onCancelUnstakeClicked = async(NFTId : number) => {
        try {
            appStore.startLoading()

            const signer = await chainStore.getSigner()
            const stakingContract = await chainStore.getMarineMogulsStakingInterface()

            const tx = await stakingContract
                .connect(signer)
                .cancelUnstakingForNFT(NFTId)

            await tx.wait(3)
            try {
                (window as any)._paq.push(['trackEvent', 'Stake', 'Cancel Unstake'])
            } catch (e) {}
            await appStore.loadMogulNFTs()
            emit('load-data')
        } catch (e) {
            processContractRunnerError(e)
            captureException(e)
            toast.error(t('errors.there_was_an_error_placeholder', {'error_message': t('errors.stake_error.canceling_unstaking')}))
        } finally {
            appStore.stopLoading()
        }
    }
    const onStakeMogulClicked = async(NFTId : number) => {
        try {
            appStore.startLoading()

            if (!isStakingContractApproved.value) await setApproveForAll()

            const signer = await chainStore.getSigner()
            const stakingContract = await chainStore.getMarineMogulsStakingInterface()

            const tx = await stakingContract
                .connect(signer)
                .stake(NFTId)

            await tx.wait(3)
            try {
                (window as any)._paq.push(['trackEvent', 'Stake', 'Stake One'])
            } catch (e) {}
            await appStore.loadMogulNFTs()
            emit('load-data')
        } catch (e) {
            processContractRunnerError(e)
            captureException(e)
            toast.error(t('errors.there_was_an_error_placeholder', {'error_message': t('errors.stake_error.staking')}))
        } finally {
            appStore.stopLoading()
        }
    }

    const onRequestUnstakeMogulClicked = async(NFTId : number) => {
        try {
            appStore.startLoading()

            const signer = await chainStore.getSigner()
            const stakingContract = await chainStore.getMarineMogulsStakingInterface()

            const tx = await stakingContract
                .connect(signer)
                .requestUnstakingForNFT(NFTId)

            await tx.wait(3)
            try {
                (window as any)._paq.push(['trackEvent', 'Stake', 'Request Unstake One'])
            } catch (e) {}
            await appStore.loadMogulNFTs()
            emit('load-data')
        } catch (e) {
            processContractRunnerError(e)
            captureException(e)
            toast.error(t('errors.there_was_an_error_placeholder', {'error_message': t('errors.stake_error.request_unstake')}))
        } finally {
            appStore.stopLoading()
        }
    }

    const onUnstakeMogulClicked = async(NFTId : number) => {
        try {
            appStore.startLoading()

            const signer = await chainStore.getSigner()
            const stakingContract = await chainStore.getMarineMogulsStakingInterface()

            const tx = await stakingContract
                .connect(signer)
                .unstake(NFTId)

            await tx.wait(3)

            try {
                (window as any)._paq.push(['trackEvent', 'Stake', 'Unstake One'])
            } catch (e) {}

            await appStore.loadMogulNFTs()
            emit('load-data')
        } catch (e) {
            processContractRunnerError(e)
            captureException(e)
            toast.error(t('errors.there_was_an_error_placeholder', {'error_message': t('errors.stake_error.unstaking')}))
        } finally {
            appStore.stopLoading()
        }
    }

    const onRequestUnstakeAllClicked = async() => {
        try {
            appStore.startLoading()

            const signer = await chainStore.getSigner()
            const stakingContract = await chainStore.getMarineMogulsStakingInterface()

            const tx = await stakingContract
                .connect(signer)
                .requestUnstakeAll()

            await tx.wait(3)
            try {
                (window as any)._paq.push(['trackEvent', 'Stake', 'Request Unstake All'])
            } catch (e) {}
            await appStore.loadMogulNFTs()
            emit('load-data')
        } catch (e) {
            processContractRunnerError(e)
            captureException(e)
            toast.error(t('errors.there_was_an_error_placeholder', {'error_message': t('errors.stake_error.unstake_all')}))
        } finally {
            appStore.stopLoading()
        }
    }

    const onUnstakeClicked = async(NFTId : number) => {
        try {
            appStore.startLoading()

            const signer = await chainStore.getSigner()
            const stakingContract = await chainStore.getMarineMogulsStakingInterface()

            const tx = await stakingContract
                .connect(signer)
                .unstake(NFTId)

            await tx.wait(3)
            try {
                (window as any)._paq.push(['trackEvent', 'Stake', 'Unstake One'])
            } catch (e) {}
            await appStore.loadMogulNFTs()
            emit('load-data')
        } catch (e) {
            processContractRunnerError(e)
            captureException(e)
            toast.error(t('errors.there_was_an_error_placeholder', {'error_message': t('errors.stake_error.unstaking')}))
        } finally {
            appStore.stopLoading()
        }
    }
    onMounted(async() => {
        await checkIfApproved()
        await getMetamaskVersion()
    })
</script>

<style>
#my-moguls .big-button:hover .big-button-icon {
  color: #BBDFE3;
}
.metamask-alert {
  background-color: var(--color-turqoise);
  color: black;
}
</style>
