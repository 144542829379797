<template>
    <Card background="black" border="transparent" rounded-class="rounded-t-lg" card-class="pt-3">
        <div class="mt-4 mb-8 px-3 md:px-5 bg-black-2">
            <h1 class="text-2xl font-semibold text-center">{{$t('my_marine_moguls.my_marine_moguls')}}</h1>
            <hr class="hr !border-white my-4"/>
            <div class="my-4 flex gap-2 items-center w-full" v-if="marineMogulsInWallet.length > 1">
                <span class="text-blue-light font-bold">{{$t('my_marine_moguls.sort_by')}}:</span>
                <Button square class="!px-2" :variant="`${sortDirection === 1 ? 'light-blue' : 'default'}`" size="small" @click="sortDirection = 1"
                ><span class="font-semibold">{{$t('my_marine_moguls.nft_id')}}</span></Button>
                <Button square class="!px-2" :variant="`${sortDirection === 2 ? 'light-blue' : 'default'}`" size="small"  @click="sortDirection = 2"
                ><span class="font-semibold">{{$t('my_marine_moguls.position_in_wallet')}}</span></Button>
                <div class="flex-1 flex justify-end">
                    <TooltipComponent>
                        <InfoCircleIcon class="h-full text-blue-light cursor-pointer"/>
                        <template #text>
                            {{$t('my_marine_moguls.sort_by')}} <b>{{$t('my_marine_moguls.nft_id')}}</b>: {{$t('my_marine_moguls.nft_id_explanation')}}<br>
                            {{$t('my_marine_moguls.sort_by')}} <b>{{$t('my_marine_moguls.position_in_wallet')}}</b>: {{$t('my_marine_moguls.position_in_wallet_description')}}
                        </template>
                    </TooltipComponent>
                </div>
            </div>
            <template v-if="marineMogulsInWallet && marineMogulsInWallet.length > 1">
                <Carousel :breakpoints="carouselBreakpoints" :items="marineMogulsInWallet" @slide-changed="onSlideChanged"
                          @sufficient-slides-for-breakpoint="onSufficientSlidesForBreakpointChanged" ref="carousel">
                    <template #slide="{item, index}: {item: Mogul, index: number}">
                        <div @click="onMogulCardClicked(index)" class="flex h-full">
                            <MogulCard class="cursor-pointer" :data="item" :is-last-in-queue="item.user_queue_index === highestQueueIndex"
                                       :show-black-overlay="carouselIndex !== index"/>
                        </div>
                    </template>
                    <template #pagination="{slider}: {slider: KeenSliderInstance}"  v-if="showCarouselArrows">
                        <div class="w-full flex justify-between mt-2 items-center">
                            <IconChevronsLeft :size="appStore.isMobile() ? 35 : 50" class="text-green-extra-light-2 hover:text-blue-light transition-all cursor-pointer" @click="slider.prev()"/>
                            <IconLayoutGrid :size="35" class="text-green-extra-light-2 hover:text-blue-light transition-all cursor-pointer" @click="onOpenMogulsGridCard"/>
                            <IconChevronsRight :size="appStore.isMobile() ? 35 : 50" class="text-green-extra-light-2 hover:text-blue-light transition-all cursor-pointer" @click="slider.next()"/>
                        </div>
                    </template>
                </Carousel>
            </template>
            <template v-else-if="!marineMogulsInWallet || marineMogulsInWallet.length === 0">
                <div class="text-center">
                    <span>{{$t('my_marine_moguls.no_moguls_text')}}</span>
                </div>
            </template>
        </div>
        <div class="bg-green-extra-light-2 rounded-b-lg -mb-2">
            <MogulCard v-if="marineMogulsInWallet && marineMogulsInWallet.length >0"
                       class="-mt-4" :data="marineMogulsInWallet[carouselIndex]"
                       :is-last-in-queue="marineMogulsInWallet[carouselIndex].user_queue_index === highestQueueIndex"
                       :current-state="MogulState.Large" @action-clicked="onActionClicked"/>
            <GhostMogulCard v-else :data="ghostMogul"/>
            <div class="p-3 lg:p-5 mt-8">
                <div class="p-4 bg-blue-dark rounded-lg">
                    <p class="text-sm lg:text-base text-black text-center">{{$t('my_marine_moguls.my_tokens')}}</p>
                    <h1 class="text-2xl lg:text-3xl text-black text-center font-semibold mt-3">{{formatNumber(MOGULBalance.mogul_balance, {NAText: (0).toLocaleString()})}} $MOGUL</h1>
                </div>
                <p class="text-base lg:text-lg text-black text-center font-semibold mt-8">{{$t('my_marine_moguls.buy_mogul_text', [formatNumber(MOGULBalance.mogul_to_whole_number)])}}</p>
                <div class="flex justify-center mt-4">
                    <a href="https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x0c9bb15b32334bDAA7Ad319FA356Dd3E8e184564" target="_blank">
                        <Button font-size="!text-xl lg:!text-2xl">{{$t('general.buy_now')}}</Button>
                    </a>
                </div>
            </div>
        </div>

        <MogulsSelectorsModal :show="showMogulsSelectorModal"  v-if="showCarouselArrows"
                              v-on:close="showMogulsSelectorModal = false" @mogul-clicked="onMogulCardInModalClicked"/>
    </Card>
</template>

<script setup lang="ts">
    import Card from '@/components/Card.vue'
    import MogulCard from '@/components/marineMoguls/components/MogulCard.vue'
    import Carousel from '@/components/Carousel.vue'
    import { IconChevronsLeft, IconChevronsRight, IconLayoutGrid } from '@tabler/icons-vue'
    import {computed, defineAsyncComponent, onMounted, type Ref, ref} from 'vue'
    import {MogulCardActionType, MogulState, StakingStatus} from '@/enums/mogul'
    import {useAppStore} from '@/stores/app'
    import type {Mogul, MogulCardAction} from '@/types/Mogul'
    import {useRouter} from 'vue-router'
    import {formatNumber} from '@/libs/helpers'
    import {getFullImageNameForMogul, getGhostMogul} from '@/libs/moguls'
    import GhostMogulCard from '@/components/marineMoguls/components/GhostMogulCard.vue'
    import type {KeenSliderInstance} from 'keen-slider/vue.es'
    import {useChainStore} from '@/stores/chain'
    import type {WebConfig} from '@/types/Common'
    import Button from '@/components/Button.vue'
    import {InfoCircleIcon} from 'vue-tabler-icons'
    import TooltipComponent from '@/components/TooltipComponent.vue'
    const MogulsSelectorsModal = defineAsyncComponent(() => import('@/components/MogulsSelectorsModal.vue'))
    const appStore = useAppStore()
    const router = useRouter()
    const chainStore = useChainStore()

    const emits = defineEmits<{(e: 'mogul-selected-in-modal', value: number):void}>()

    // data
    const carouselIndex : Ref<number> = ref(0)
    const ghostMogul : Ref<Mogul> = ref(getGhostMogul())
    const webConfig : Ref<WebConfig|undefined> = ref(undefined)
    const showCarouselArrows : Ref<Boolean> = ref(true)
    const carousel = ref()
    const sortDirection : Ref<number> = ref(1)
    const showMogulsSelectorModal : Ref<boolean> = ref(false)

    const carouselBreakpoints = {
        '(min-width: 290px)': {
            slides: {perView: 3, spacing: 8}
        },
        '(min-width: 430px)': {
            slides: {perView: 3, spacing: 8}
        },
        '(min-width: 640px)': {
            slides: {perView: 4, spacing: 10}
        },
        '(min-width: 1024px)': {
            slides: {perView: 3, spacing: 5}
        },
        '(min-width: 1536px)': {
            slides: {perView: 4, spacing: 8}
        }
    }

    // computed
    const marineMogulsInWallet = computed(() => {
        return [...appStore.getMarineMoguls()].sort((a, b) => {
            if (sortDirection.value === 1) return a.nft_id - b.nft_id
            else return a.user_queue_index - b.user_queue_index
        })
    })
    const MOGULBalance = computed(() => {
        return appStore.getMOGULBalance()
    })
    const highestQueueIndex = computed(() => {
        // if user doesn't have MOGUL or has only one, user doesn't need to see queue info
        if (marineMogulsInWallet.value.length <= 1) return -9999
        return Math.max(...marineMogulsInWallet.value.filter(m => m.staking_status === StakingStatus.InWallet).map(m => m.user_queue_index))
    })

    // methods

    const onOpenMogulsGridCard = () => {
        showMogulsSelectorModal.value = true
    }
    const onMogulCardClicked = (index: number) => {
        if (!carousel.value // fallback, should come to this
            || !showCarouselArrows.value) { // if carousel is disabled (less NFTs) than slides per breakpoint
            carouselIndex.value = index
            return
        }
        carousel.value.moveToIndex(index)
    }
    const onMogulCardInModalClicked = (mogulId : number) => {
        const idxByNFTID = marineMogulsInWallet.value.findIndex(m => m.nft_id === mogulId)
        if (idxByNFTID === -1) return
        emits('mogul-selected-in-modal', mogulId)
        onMogulCardClicked(idxByNFTID)
    }
    const onActionClicked = async(action : MogulCardAction) => {
        if (!action) return

        if (action.type === MogulCardActionType.Merge) {
            await router.push({name: 'merge', params: {mogul_id: action.nft_id}})
        } else if (action.type === MogulCardActionType.Craft) {
            await router.push({name: 'craft', params: {mogul_id: action.nft_id}})
        } else if (action.type === MogulCardActionType.Prize) {
            await router.push({name: 'prize', params: {mogul_id: action.nft_id}})
        } else if (action.type === MogulCardActionType.Send) {
            await router.push({name: 'send', params: {mogul_id: action.nft_id}})
        }
    }

    const onSufficientSlidesForBreakpointChanged = (isSufficient : boolean) => {
        showCarouselArrows.value = isSufficient
    }

    const preloadNFTImagesFromIndex = (idx : number) => {
        marineMogulsInWallet.value.slice(idx, idx + 3)
            .forEach(nft => {
                appStore.preloadImage(getFullImageNameForMogul(nft.nft_id, 'full', nft.level))
            })
    }

    const onSlideChanged = (newIndex : number) => {
        carouselIndex.value = newIndex
        preloadNFTImagesFromIndex(newIndex)
    }


    // lifecycle hooks
    onMounted(async() => {
        webConfig.value = await chainStore.getWebConfig()
        preloadNFTImagesFromIndex(0)
    })
</script>
