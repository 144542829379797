import {init, addBreadcrumb, type Breadcrumb} from '@sentry/vue'
import {browserTracingIntegration, extraErrorDataIntegration  } from '@sentry/vue'
import router from '@/router'
import type {App} from 'vue'


export function createSentry(app: App<Element>) {
    init({
        app,
        dsn: 'https://e458d1507e892b04a7c17699cd27e159@sentry.metabid.ai/121',
        tracePropagationTargets: ['localhost', 'localhost:5173', '*.marinemoguls.com', '*.metfi.dev', '*.metfi.io'],
        integrations: [
            browserTracingIntegration({
                router
            }),
            extraErrorDataIntegration()
        ],
        maxBreadcrumbs: 50,
        beforeBreadcrumb(breadcrumb: Breadcrumb) {
            if (breadcrumb.category === 'ui.click' && breadcrumb.message) {
                const regex = new RegExp('name="(.*?)"')
                const result = regex.exec(breadcrumb.message)

                if (result && result.length >= 2) {
                    addBreadcrumb({
                        category: 'Custom',
                        message: `Clicked button ${result[1]}`,
                        level: 'info'
                    })
                }

                return null
            } else if (breadcrumb.category === 'xhr') {
                if (breadcrumb.data && breadcrumb.data.url) {
                    const url = breadcrumb.data.url

                    const urlsToIgnore: string[] = []

                    if (urlsToIgnore.includes(url)) {
                        return null
                    }

                }
            }

            return breadcrumb
        },
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0
    })
}
