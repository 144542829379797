enum MogulState {
    Small = 1,
    Medium,
    Large
}

enum MogulCardActionType {
    Craft = 1,
    Merge = 2,
    Prize = 3,
    Send = 4,
}

enum StakingStatus {
    Staked = 'Staked',
    InWallet = 'InWallet',
    InUnstaking = 'InUnstaking',
    EnteringUnstaking = 'EnteringUnstaking',
    UnstakeAvailable = 'UnstakeAvailable',
    EnteringStaking = 'EnteringStaking',
    Warmup = 'Warmup'
}

enum PrizeStatus {
    NoPrize = 1,
    CanClaim = 2,
    PrizeClaimed = 3,
    UpgradeRequired = 4
}

enum PrizeType {
    USDT = 'USDT',
    METFI = 'METFI',
    UNSTAKED = 'UNSTAKED',
    MATCHING = 'MATCHING',
    METBOT = 'METBOT',
}

enum RarityNumeric {
    Uncommon = 1,
    Rare,
    Epic,
    Legendary,
    Mythic,
}

const getNumericRarity = (rarity: string) => {
    switch (rarity) {
        case 'Uncommon':
            return 1
        case 'Rare':
            return 2
        case 'Epic':
            return 3
        case 'Legendary':
            return 4
        case 'Mythic':
            return 5
    }
    return 0
}

export {MogulState, MogulCardActionType, StakingStatus, PrizeStatus, PrizeType, RarityNumeric, getNumericRarity}
