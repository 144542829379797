<template>
    <div class="relative">
        <div class="big-m"></div>
        <Header/>

        <div class="container px-2 sm:px-4 lg:px-16 xl:px-32 mx-auto">
            <Dev_Settings v-if="displayDevSettings"/>
            <BackButton/>
            <RouterView/>
        </div>

        <div
            v-if="appStore.isLoading()"
            class="loaderStyle flex flex-wrap items-center justify-center"
        >
            <LoaderComponent />
        </div>

    </div>
</template>
<script setup lang="ts">
    import {onMounted, onBeforeMount, onBeforeUnmount, computed, defineAsyncComponent} from 'vue'
    import {useAppStore} from '@/stores/app'
    import Header from '@/components/header/Header.vue'
    import LoaderComponent from '@/components/loaders/LoaderComponent.vue'
    import {useChainStore} from '@/stores/chain'
    const Dev_Settings = defineAsyncComponent(() => import('@/components/Dev_Settings.vue'))
    import BackButton from '@/components/BackButton.vue'
    import {isDevHostname} from '@/libs/helpers'

    const appStore = useAppStore()
    const chainStore = useChainStore()

    const displayDevSettings = computed(() => {
        return isDevHostname()
    })
    const onWindowResized = () => {
        appStore.resizeHandler()
    }
    const onBeforeUnload = () => {
        window.removeEventListener('resize', onWindowResized)
        window.removeEventListener('beforeunload', onBeforeUnload)
        appStore.stopCurrentTimestampInterval()
    }

    onBeforeMount(async() => {
        await chainStore.registerMetaMaskAndWalletConnectAddressListeners()
    })


    onBeforeUnmount(() => {
        onBeforeUnload()
    })

    // lifecycle hooks
    onMounted(async() => {
        onWindowResized()
        window.addEventListener('resize', onWindowResized)
        appStore.resizeHandler()
        appStore.startCurrentTimestampInterval()
        setTimeout(() => {
            const element = document.getElementById('splash-screen-loader')
            if (element) element.style.display = 'none'
        }, 50)

        // beforeunload gets always called when page is refreshed or closed
        window.addEventListener('beforeunload', onBeforeUnload)

        await Promise.all([
            appStore.loadTreasuryData(),
            appStore.loadMOGULBalance()
        ])
    })
</script>

<style scoped>
.loaderStyle {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 89;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px);
}
.big-m {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("./images/big_mm.svg");
  background-repeat: no-repeat;
  background-position: 20px 100px;
  z-index: -1;
}
@media screen and (max-width: 850px) {
  .big-m {
    background-size: 80%;
  }
}
</style>
