<template>
    <div>
        <Button circle class="hamburger-menu-button menu-button mx-auto z-[9] w-full" @click="emits('menu-toggled')">
            <HamburgerIcon class="min-w-[20px] h-full" />
        </Button>
        <div class="grid grid-cols-5 gap-1 sm:gap-3 px-3 menu -mt-[30px] z-[8] relative"
             :style="{
                 maxHeight: isMenuOpen ? '300px' : '0',
             }"
        >
            <div
                class="flex flex-col justify-between items-center gap-2 text-center w-full pt-[60px] pb-2"
                v-for="(item, idx) of menuItems"
                v-bind:key="`menu-item-${idx}`" v-once
            >
                <a :href="item.url" target="_blank">

                    <Button
                        circle
                        class="menu-button max-[290px]:w-4 max-[290px]:h-4 w-[40px] h-[40px] sm:w-[50px] sm:h-[50px] lg:w-[40px] lg:h-[40px] xl:w-[50px] xl:h-[50px]"
                        :class="buttonSize"
                    >
                        <component :is="item.icon" class="min-w-3 min-h-3 w-full h-full" />
                    </Button>
                </a>
                <span
                    class="text-dark-gray text-[12px] sm:text-[14px] leading-3 break-all"
                >{{ item.title }}</span
                >
            </div>
        </div>
    </div>
</template>
<style scoped>
.menu {
    background: rgba(255, 255, 255, 0.75);
}
</style>
<script setup lang="ts">
    import Button from '@/components/Button.vue'
    import {type Component, type Ref, shallowRef} from 'vue'
    import HomeIcon from '@/assets/icons/HomeIcon.vue'
    import PuzzleIcon from '@/assets/icons/PuzzleIcon.vue'
    import FourSquaresIcon from '@/assets/icons/FourSquaresIcon.vue'
    import PersonPlusIcon from '@/assets/icons/PersonPlusIcon.vue'
    import ShieldPlusIcon from '@/assets/icons/ShieldPlusIcon.vue'
    import HamburgerIcon from '@/assets/icons/HamburgerIcon.vue'
    import {useI18n} from 'vue-i18n'

    defineProps({
        isMenuOpen: Boolean
    })

    interface MenuItem {
        title: string
        icon: Component,
        url: string
    }

    // data
    const t = useI18n().t

    const buttonSize = () => {
        const size = 24
        return `min-w-[${size}px] min-h-[${size}px] max-h-[${size}px] max-w-[${size}px] w-full h-full`
    }

    const menuItems: Ref<MenuItem[]> = shallowRef([
        { title: t('menu.dashboard'), icon: HomeIcon, url: 'https://app.metfi.io' },
        { title: t('menu.upgrade'), icon: PuzzleIcon, url: 'https://app.metfi.io'  },
        { title: t('menu.stake'), icon: FourSquaresIcon, url: 'https://app.metfi.io/add-tokens' },
        { title: t('menu.my_team'), icon: PersonPlusIcon, url: 'https://app.metfi.io/statistics/my-referrals'  },
        { title:  t('menu.dao'), icon: ShieldPlusIcon, url: 'https://dao.metfi.io' }
    ])
    const emits = defineEmits<{(e: 'menu-toggled') : void}>()
</script>
<style scoped>
.menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
}
.hamburger-menu-button {
    max-width: 60px;
    outline: 6px solid var(--color-light-green);
  position: relative;
}
  .menu {
  overflow: hidden;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    position: relative;
    transition: max-height 0.5s ease;
}


</style>
