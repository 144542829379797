<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.12207 9.61785L9.167 1.57292L17.2119 9.61785C17.2465 9.65245 17.2863 9.67871 17.3287 9.69685V9.73482H17.4787C17.5742 9.73482 17.6716 9.69895 17.7454 9.62516C17.8917 9.47893 17.8917 9.23788 17.7454 9.09166L9.43375 0.779981C9.28753 0.633756 9.04648 0.633757 8.90025 0.77998L0.588621 9.08431L0.588574 9.08436C0.442351 9.23058 0.44235 9.47163 0.588574 9.61785C0.734798 9.76408 0.975848 9.76408 1.12207 9.61785Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.3"
        />
        <path
            d="M11.2334 17.4632H15.2432C15.4502 17.4632 15.6196 17.2937 15.6196 17.0867V7.31431C15.6196 7.1073 15.4502 6.93789 15.2432 6.93789C15.0361 6.93789 14.8667 7.1073 14.8667 7.31431V16.7103H11.6098V11.105C11.6098 10.898 11.4404 10.7285 11.2334 10.7285H7.09946C6.89245 10.7285 6.72304 10.898 6.72304 11.105V16.7103H3.46611V7.31431C3.46611 7.1073 3.2967 6.93789 3.0897 6.93789C2.88269 6.93789 2.71328 7.1073 2.71328 7.31431V17.0867C2.71328 17.2937 2.88269 17.4632 3.0897 17.4632H7.09216C7.29917 17.4632 7.46857 17.2937 7.46857 17.0867V11.4814H10.857V17.0867C10.857 17.2937 11.0264 17.4632 11.2334 17.4632Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.3"
        />
    </svg>
</template>
