import type {Mogul} from '@/types/Mogul'
import {useAppStore} from '@/stores/app'
import {isDevHostname} from '@/libs/helpers'

const gallery = Object.values(import.meta.glob('@/assets/images/ghost_moguls/*.webp', { eager: true, query: '?url', import: 'default' }))
const getGhostMogul = () : Mogul => {
    const appStore = useAppStore()
    const wallet = appStore.getLoggedInUser().WalletAddress
    let randomIndex = parseInt(wallet.slice(wallet.length / 3, wallet.length)) % gallery.length
    if (isNaN(randomIndex)) randomIndex = 0

    const randomGhostImage = gallery[randomIndex]

    return {
        attributes: [],
        nft_id: 0,
        level: 0,
        initial_rarity_rank: 0,
        tier: '',
        initial_tier: '',
        headwear: '',
        eyewear: '',
        neckwear: '',
        attire: '',
        handheld_items: '',
        backgrounds: '',
        upgraded: false,
        merged: false,
        upgradeable: false,
        mergable: false,
        name: 'Ghost Mogul',
        description: 'Ghost Mogul',
        image: randomGhostImage,
        external_url: '',
        staking_status: '',
        is_tide_turner: false,
        is_golden: false,
        has_hidden_prize: false,
        hidden_prize_claimed: false,
        user_queue_index: 0
    } as Mogul
}

const getImageNameForMogul = (mogul : Mogul) => {
    return `${mogul.nft_id}`
}
const validSizes = ['full', '512_512', '256_256']
const getFullImageNameForMogul = (mogulNFTId : number, size: string, level : number = 0) => {
    if (!validSizes.includes(size)) return ''

    return `${isDevHostname() ? '' : 'https://images.marinemoguls.com'}/api/images/v1/${size}/${mogulNFTId}${level ? `?level=${level}` : ''}`
}

export {getGhostMogul, getImageNameForMogul, getFullImageNameForMogul}
