<template>
    <div class="flex items-center border-[1px] border-default rounded-lg gap-3 py-1 px-4 w-full bg-white"
         :class="{'border-glow': showInvalid}">
        <input
            :ref="id"
            class="outline-none w-full"
            :class="[textSize]"
            style="border: transparent 2px solid"
            autocapitalize="off"
            :disabled="disabled"
            :value="modelValue"
            :placeholder="placeholder"
            :type="type"
            :maxlength="maxLength"
            :tabindex="tabIndex"
            @blur="(e) => $emit('blur', e)"
            @keyup="(e) => $emit('keyup', e)"
            @input="(e) => $emit('update:modelValue', (e.target as HTMLInputElement).value)"
            @keyup.enter="(e) => $emit('keyup.enter', e)"
        >
    </div>

</template>

<script lang="ts" setup>
    import {generateRandomId} from '@/libs/helpers'
    import {onBeforeMount, type Ref, ref} from 'vue'

    const props = defineProps({
        modelValue: [String, Number],
        placeholder: String,
        type: {
            type: String,
            default: 'text'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        maxLength: {
            type: Number,
            default: 100000
        },
        showCharCount: {
            type: Boolean,
            default:false
        },
        tabIndex:{
            type: Number,
            default:0
        },
        textSize: {
            type: String,
            default: 'text-2xl lg:text-3xl'
        }
    })

    const id: Ref<string> = ref('')
    const showInvalid = ref(false)

    function showInvalidState() {
        showInvalid.value = true
        setTimeout(() => {
            showInvalid.value = false
        }, 1000)
    }

    defineExpose({
        showInvalidState
    })

    onBeforeMount(() => {
        id.value = generateRandomId('custom-input')
    })
</script>
<style scoped>

.border-glow {
  animation: glow 0.7s cubic-bezier(0, 0, 0.2, 1) 1;
}
@-webkit-keyframes glow {
  0% {
    border: #CCDDDC 1px solid;
  }
  50% {
    border: #e41b23b3 1px solid;
  }
  100% {
    border: #CCDDDC 1px solid;
  }
}
@keyframes glow {
  0% {
    border: #CCDDDC 1px solid;
  }
  50% {
    border: #e41b23b3 1px solid;
  }
  100% {
    border: #CCDDDC 1px solid;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
</style>
