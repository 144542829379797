<template>
    <div
        class="flex flex-col items-center gap-1 text-center"
    >
        <Button
            circle
            class="max-[300px]:w-[40px] max-[300px]:h-[40px] w-[50px] h-[50px] xl:w-[65px] xl:h-[65px] p-3"
            :class="buttonSize" :disabled="disabled"
            variant="light-blue"
        >
            <slot name="icon"></slot>
        </Button>
        <span class="text-green-extra-light-2 text-[12px] sm:text-[14px] leading-3 max-[350px]:break-all uppercase">
            <slot></slot>
        </span>
    </div>
</template>
<script setup lang="ts">
    import Button from '@/components/Button.vue'

    defineProps({
        disabled: {
            type: Boolean,
            default: false
        }
    })
    const buttonSize = () => {
        const size = 24
        return `min-w-[${size}px] min-h-[${size}px] max-h-[${size}px] max-w-[${size}px] w-full h-full`
    }
</script>
