import {useI18n} from 'vue-i18n'
import i18n from '@/libs/i18n'
import {toast} from 'vue3-toastify'

const generateRandomId = (prefix: string = ''): string => {
    return `${prefix ? `${prefix}-` : ''}${(Math.random() * 1e6).toFixed(0)}`
}

const shortAddress = (val: string): string => {
    if (!val) {
        return ''
    }

    return `${val.substring(0, 6)}...${val.substring(val.length - 5, val.length)}`
}

const formatNumber = (
    val: number | string | null | undefined,
    {divisionNum = 1000, decimals = 2, NAText = '0.00'} = {}
) => {

    if (NAText.startsWith('0')) {
        const NATextDecimals = NAText.split(NAText.includes('.') ? '.' : ',') // try split . or ,
        let NATextDecimalsCount = 0
        if (NATextDecimals.length === 2) NATextDecimalsCount = NATextDecimals[1].length

        // set NAText to user native decimals with decimals count passed as NAText
        NAText = (0).toLocaleString(undefined, {
            maximumFractionDigits: NATextDecimalsCount,
            minimumFractionDigits: NATextDecimalsCount
        })
    }

    if (!val) return NAText

    const num = Number(val)
    if (isNaN(num)) return NAText

    const numToDisplay = num / (divisionNum ?? 1000)
    const fractionDigits = numToDisplay < 1_000_000 ? decimals ?? 2 : 0

    return numToDisplay.toLocaleString(undefined, {
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits
    })
}

const padDigit = (val: number) => {
    return val < 10 ? `0${val}` : val
}

const generateRandomString = (
    length = 5,
    characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
): string => {
    let result = ''
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }

    return result
}

const addressValidator = (address: string): boolean => {
    if (address === undefined || address === null || address.length === 0 || address.length > 42) {
        return false
    }
    const regex = /0x[a-fA-F0-9]{40}/
    return regex.test(address)
}

const isNumber = (value: string | number): boolean => {
    return !isNaN(Number(value))
}

const selectRandomReferral = (): string => {
    const mainReferrals = [
        '3ec251928dd6e3c49b467cbf1811337a',
        'a4d0569cf2ce7ab76631a19149c98c7c',
        '90eef6f5617b2895cfd9f86cf28abec3'
    ]
    return mainReferrals[Math.floor(Math.random() * 3)]
}

const selectRandomWordForFinishedAuction = (): string => {
    const words = [
        'auction.congratulations',
        'auction.cheers',
        'auction.bravo',
        'auction.kudos',
        'auction.well_done',
        'auction.hooray',
        'auction.celebrations',
        'auction.commendations',
        'auction.applause',
        'auction.felicitations',
        'auction.props',
        'auction.salutations',
        'auction.hurrah',
        'auction.praise',
        'auction.ovation',
        'auction.plaudits',
        'auction.tribute'
    ]

    return words[Math.floor(Math.random() * 3)]
}

const formatDollar = (val: number | string, {decimals = 2} = {}): string => {
    if (isNaN(Number(val))) return Number(0).toLocaleString(undefined, {
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals
    })
    return Number(val).toLocaleString(undefined, {
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals
    })
}

const reflowAnimation = (elRef: any) => {
    if (!elRef || !elRef.style) return
    // setting animation to none, then force browser to reflow element by calling getClientRects
    // after that, setting animation to '' tells css to inherit animation from default element (.current-price-glow)
    elRef.style.animation = 'none'
    elRef.getClientRects()
    elRef.style.animation = ''
}

const characterNameByLevel = (level: number): string => {
    const t = useI18n().t
    switch (level) {
        case 0:
            return t('characters.plankton')
        case 1:
            return t('characters.shrimp')
        case 2:
            return t('characters.crab')
        case 3:
            return t('characters.octopus')
        case 4:
            return t('characters.fish')
        case 5:
            return t('characters.dolphin')
        case 6:
            return t('characters.shark')
        case 7:
            return t('characters.orca')
        case 8:
            return t('characters.whale')
        case 9:
            return t('characters.whale_shark')
        case 10:
            return t('characters.humpback')
    }
    return ''
}

interface formatCountdownOpts {
    showSeconds?: boolean,
    showMinutes?: boolean,
    showHours?: boolean,
    showDays?: boolean
    hideZeroTimes?: boolean
}

const defaultOptions = {
    showSeconds: true,
    showMinutes: true,
    showHours: true,
    showDays: true,
    hideZeroTimes: true
}
const formatCountdown = (secondsLeft: number, opts: formatCountdownOpts = {}) => {

    const options = {...defaultOptions, ...opts}
    const {showSeconds, showMinutes, showHours, showDays, hideZeroTimes} = options

    const secondsInDay = 60 * 60 * 24
    const secondsInHour = 60 * 60

    const days = Math.floor((secondsLeft / secondsInDay)).toFixed(0)
    secondsLeft = secondsLeft % secondsInDay

    const hours = Math.floor((secondsLeft / secondsInHour)).toFixed(0)
    secondsLeft = secondsLeft % secondsInHour

    const minutes = Math.floor((secondsLeft / 60)).toFixed(0)
    const seconds = (secondsLeft % 60).toFixed(0)

    let fullText = ''

    if (showDays) {
        if (hideZeroTimes && Number(days) === 0) { /* empty */
        } else {
            fullText += `${days} ${i18n.global.t('general.day', Number(days))} `
        }
    }

    if (showHours) {
        if (hideZeroTimes && Number(hours) === 0) { /* empty */
        } else {
            fullText += `${hours} ${i18n.global.t('general.hour', Number(hours))} `
        }
    }

    if (showMinutes) {
        if (hideZeroTimes && Number(minutes) === 0) { /* empty */
        } else {
            fullText += `${minutes} ${i18n.global.t('general.minute', Number(minutes))} `
        }
    }

    if (showSeconds) {
        if (hideZeroTimes && Number(seconds) === 0) { /* empty */
        } else {
            fullText += `${seconds} ${i18n.global.t('general.second', Number(seconds))} `
        }
    }

    fullText = fullText.trim().toLowerCase()

    return {fullText, days, hours, minutes, seconds}
}

const processContractRunnerError = (error : string | object | unknown) => {
    if (JSON.stringify(error).toLowerCase().includes('contract runner does not support sending')) {
        toast.error(i18n.global.t('errors.wallet_locked'), {autoClose: 10_000, theme: 'colored'})
        return true
    }
    return false
}

const isDevHostname = () : boolean => {
    return window.location.host.includes('localhost') || window.location.host.includes('.dev')
}
export {
    generateRandomId,
    shortAddress,
    formatNumber,
    generateRandomString,
    addressValidator,
    isNumber,
    selectRandomReferral,
    selectRandomWordForFinishedAuction,
    formatDollar,
    reflowAnimation,
    characterNameByLevel,
    formatCountdown,
    processContractRunnerError,
    isDevHostname
}
