<template>
    <div class="hidden-prizes-card rounded-lg">
        <div class="text-2xl px-12 py-8 text-center">
            <div class="leading-7">
                <span class="font-bold uppercase">{{$t('hidden_prize.stats.title_1')}}<br>{{$t('hidden_prize.stats.title_2')}}</span>
            </div>
            <div class="leading-7 mt-0.5">
                <span class="uppercase">{{$t('hidden_prize.stats.still_unclaimed')}}</span>
            </div>
        </div>
        <div class="white-separator"/>

        <div class="prizes">
            <div class="text-center pt-8 px-4 pb-4">
                <span class="text-2xl font-bold">{{$t('hidden_prize.stats.usdt_cash_prize')}}</span>
            </div>
            <div class="hr-title mx-4"/>
            <div class="text-xl py-2">
                <div class="flex justify-between px-4 py-1" v-for="(usdtPrize, i) of USDTPrizes" v-bind:key="`usdt-${i}`"
                     :class="{'prize-claimed' : usdtPrize.available === 0}">
                    <span class="font-medium">{{formatNumber(usdtPrize.amount, {divisionNum: 1, decimals: 0})}} USDT</span>
                    <span class="font-medium">
                        {{ usdtPrize.available === 0 ? `${$t('hidden_prize.stats.all_claimed')}` : `${usdtPrize.available}/${usdtPrize.total}`}}
                    </span>
                </div>
            </div>
        </div>
        <div class="white-separator"/>
        <div class="prizes rounded-b-lg">
            <div class="text-center pt-8 px-4 pb-4">
                <span class="text-2xl font-bold">{{$t('hidden_prize.stats.metfi_prizes')}}</span>
            </div>
            <div class="hr-title mx-4"/>
            <div class="text-xl py-2">
                <div class="flex justify-between px-4 py-1" v-for="(metfiPrize, i) of METFIPrizes" v-bind:key="`metfi-${i}`"
                     :class="{'prize-claimed' : metfiPrize.available === 0}">
                    <span class="font-medium">{{formatNumber(metfiPrize.amount, {divisionNum: 1, decimals: 0})}} METFI</span>
                    <span class="font-medium">
                        {{ metfiPrize.available === 0 ? `${$t('hidden_prize.stats.all_claimed')}`  : `${metfiPrize.available}/${metfiPrize.total}`}}
                    </span>
                </div>

                <div class="flex justify-between px-4 py-1" v-if="matchingPrize"
                     :class="{'prize-claimed' : matchingPrize.available === 0}">
                    <span class="font-medium">{{$t('hidden_prize.stats.matching_bonus_powerup')}}</span>
                    <span class="font-medium">
                        {{ matchingPrize.available === 0 ? `${$t('hidden_prize.stats.all_claimed')}` : `${matchingPrize.available}/${matchingPrize.total}`}}
                    </span>
                </div>

                <div class="flex justify-between px-4 py-1" v-if="unstakingPrize"
                     :class="{'prize-claimed' : unstakingPrize.available === 0}">
                    <span class="font-medium">{{$t('hidden_prize.stats.unstaked_powerup')}}</span>
                    <span class="font-medium">
                        {{ unstakingPrize.available === 0 ? `${$t('hidden_prize.stats.all_claimed')}`  : `${unstakingPrize.available}/${unstakingPrize.total}`}}
                    </span>
                </div>
            </div>
            <div class="metbot-access flex justify-between text-2xl text-blue-light bg-black-2 rounded-xl px-4 py-6 mt-4">
                <div>
                    <span>{{$t('hidden_prize.stats.metbot_access')}}</span>
                </div>
                <div>
                    <span v-if="metbotPrize">
                        <template v-if="metbotPrize.available === 0">
                            {{$t('hidden_prize.stats.all_claimed')}}
                        </template>
                        <template v-else>
                            {{metbotPrize!.available}}/{{metbotPrize!.total}}
                        </template>
                    </span>
                </div>
            </div>
        </div>
        <div class="flex flex-col px-8 py-12 text-2xl text-center">
            <div class="leading-8 mt-8">
                <span>{{$t('hidden_prize.stats.grab_your_text')}}</span>
            </div>
            <div class="leading-8">
                <span class="font-medium text-blue-dark">{{$t('hidden_prize.stats.every_4th')}}</span>
            </div>
            <div class="leading-8">
                <span class="font-bold">{{$t('hidden_prize.stats.dont_miss_out')}}</span>
            </div>

            <div class="flex justify-center items-center">
                <a href="https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x0c9bb15b32334bDAA7Ad319FA356Dd3E8e184564" target="_blank">
                    <Button class="uppercase mt-12 w-fit !px-8 !text-xl">{{$t('hidden_prize.stats.get_your_mogul')}}</Button>
                </a>
            </div>
        </div>
    </div>
</template>

<style scoped>
.white-separator {
  width: 100%;
  border-top: 5px solid white;
}
.hr-title {
  border-top: 1px solid #80ADAA;
}
.hidden-prizes-card {
  background-color: #E6F3F5;
}
.prizes {
  background-color: #1C9FB140;
}
.prize-claimed {
  background-color: #E6F3F5;
  color: #A0A2A4;
}
.metbot-access {
  background-color: #262b37;

}
</style>
<script setup lang="ts">
    import Button from '@/components/Button.vue'
    import {onBeforeUnmount, onMounted, ref, type Ref} from 'vue'
    import type {AxiosResponse} from 'axios'
    import axios from '@/libs/axios'
    import {captureException} from '@sentry/vue'
    import type {HiddenPrizeCount} from '@/types/Mogul'
    import {formatNumber} from '../../libs/helpers'

    const USDTPrizes : Ref<Array<HiddenPrizeCount>> = ref([])
    const METFIPrizes : Ref<Array<HiddenPrizeCount>> = ref([])
    const unstakingPrize : Ref<HiddenPrizeCount|undefined> = ref(undefined)
    const matchingPrize : Ref<HiddenPrizeCount|undefined> = ref(undefined)
    const metbotPrize : Ref<HiddenPrizeCount|undefined> = ref(undefined)
    let interval = 0

    const loadData = async() => {
        try {
            const res : AxiosResponse<Array<HiddenPrizeCount>> = await axios.get('/api/v1/moguls/hidden_prize/counts')
            USDTPrizes.value = res.data.filter(p => p.type === 'USDT').sort((a, b) => b.amount - a.amount)
            METFIPrizes.value = res.data.filter(p => p.type === 'METFI').sort((a, b) => b.amount - a.amount)
            const unstakedHiddenPrize = res.data.filter(p => p.type === 'UNSTAKED')
            if (unstakedHiddenPrize.length > 0) unstakingPrize.value = unstakedHiddenPrize[0]

            const matchingHiddenPrize = res.data.filter(p => p.type === 'MATCHING')
            if (matchingHiddenPrize.length > 0) matchingPrize.value = matchingHiddenPrize[0]

            const metbotHiddenPrize = res.data.filter(p => p.type === 'METBOT')
            if (metbotHiddenPrize.length > 0) metbotPrize.value = metbotHiddenPrize[0]
        } catch (e) {
            captureException(e)
        }
    }

    onMounted(async() => {
        await loadData()
        interval = window.setInterval(loadData, 30 * 1000)
    })
    onBeforeUnmount(() => {
        if (interval)window.clearInterval(interval)
    })
</script>
