<template>
    <div class="header z-[10]" ref="header">
        <div class="px-4 md:px-8 py-4 flex flex-wrap justify-between gap-2 w-full">
            <router-link :to="{name: 'home'}">
                <img
                    :src="MetFiLogoFull"
                    alt="MetFi Logo"
                    class="hidden lg:block min-w-[120px] max-w-[120px] w-full lg:ml-[10%]"
                />
                <img :src="MetFiLogo" alt="MetFi Logo" class="block lg:hidden !min-h-[20px] max-w-[50px] w-full pr-4" />
            </router-link>
            <div class="flex flex-col-reverse h-full sm:flex-row gap-2 md:gap-4 mr-4 relative items-end sm:items-stretch">
                <MultiNFTSelect class="h-full" v-if="NFTCount > 1"/>
                <WalletPill class="z-[6]" />
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
    import MetFiLogoFull from '@/assets/images/MetFiLogoFull.svg'
    import MetFiLogo from '@/assets/images/MetFiLogo.svg'
    import WalletPill from '@/components/header/components/WalletPill.vue'
    import {computed, defineAsyncComponent, ref} from 'vue'
    import {useAppStore} from '@/stores/app'
    const MultiNFTSelect = defineAsyncComponent(() => import('@/components/header/components/MultiNFTSelect.vue'))
    const header = ref()
    const appStore = useAppStore()

    const NFTCount = computed(() => {
        if (!appStore.getWalletNFTs()) return 0
        return appStore.getWalletNFTs().length
    })


</script>
<style scoped>
.header {
  position: sticky;
  top: 0;
  background-color: var(--color-background);
}
</style>
