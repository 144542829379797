<template>
    <div>
        <div class="flex items-center max-[300px]:justify-between justify-center max-[300px]:gap-2 gap-6 sm:gap-4">
            <CardButton @click="isCraftable && emits('click', MogulCardActionType.Craft)"
                        :disabled="!isCraftable">
                <template #default>
                    {{t('moguls.craft')}}
                </template>
                <template #icon>
                    <CraftIcon class="min-w-3 min-h-3 w-full h-full"/>
                </template>
            </CardButton>
            <CardButton @click="isMergeable && emits('click', MogulCardActionType.Merge)"
                        :disabled="!isMergeable">
                <template #default>
                    {{t('moguls.merge')}}
                </template>
                <template #icon>
                    <MergeIcon class="min-w-3 min-h-3 w-full h-full"/>
                </template>
            </CardButton>
            <CardButton @click="isPrizeable && emits('click', MogulCardActionType.Prize)"
                        :disabled="!isPrizeable">
                <template #default>
                    {{t('moguls.prize')}}
                </template>
                <template #icon>
                    <PrizeIcon class="min-w-3 min-h-3 w-full h-full"/>
                </template>
            </CardButton>
            <CardButton @click="isInWallet && emits('click', MogulCardActionType.Send)"
                        :disabled="!isInWallet">
                <template #default>
                    {{t('moguls.send')}}
                </template>
                <template #icon>
                    <IconSend2 class="min-w-3 min-h-3 w-full h-full"/>
                </template>
            </CardButton>
        </div>
        <div v-if="!isInWallet" class="font-bold text-center pt-4 leading-5">
            <span class="text-blue-extra-light">{{$t('moguls.mogul_not_in_wallet')}}</span>
        </div>
        <div v-if="!hasMETFINFT" class="font-bold text-center pt-4 leading-5">
            <span class="text-blue-extra-light">{{$t('moguls.must_hold_metfi_nft')}}</span>
        </div>
    </div>
</template>

<script setup lang="ts">
    import CraftIcon from '@/assets/icons/CraftIcon.vue'
    import MergeIcon from '@/assets/icons/MergeIcon.vue'
    import PrizeIcon from '@/assets/icons/PrizeIcon.vue'
    import {computed, type PropType} from 'vue'
    import {useI18n} from 'vue-i18n'
    import {MogulCardActionType, StakingStatus} from '@/enums/mogul'
    import type {Mogul} from '@/types/Mogul'
    import CardButton from '@/components/marineMoguls/components/CardButton.vue'
    import {useAppStore} from '@/stores/app'
    import {IconSend2} from '@tabler/icons-vue'

    const t = useI18n().t
    const appStore = useAppStore()
    const emits = defineEmits<{(e: 'click', value: number) : void}>()

    // props
    const props = defineProps({
        mogulNFT: {
            type: Object as PropType<Mogul>,
            required: true
        }
    })

    const isMMHigherLevelThanNFT = computed(() => {
        const selectedNFT = appStore.getSelectedNFT()
        if (!selectedNFT) return false
        return  selectedNFT.level > props.mogulNFT.level
    })

    const isInWallet = computed(() => {
        if (!props.mogulNFT) return false
        return props.mogulNFT.staking_status === StakingStatus.InWallet
    })

    const hasMETFINFT = computed(() => {
        return appStore.getWalletNFTs().length !== 0
    })

    const selectedNFT = computed(() => {
        return appStore.getSelectedNFT()
    })

    const isCraftable = computed(() => {
        if (!props.mogulNFT || !selectedNFT.value) return false
        return props.mogulNFT.upgradeable && isMMHigherLevelThanNFT.value && isInWallet.value && hasMETFINFT.value && props.mogulNFT.level !== selectedNFT.value!.level
    })

    const isMergeable = computed(() => {
        if (!props.mogulNFT) return false
        return props.mogulNFT.mergable && !props.mogulNFT.merged && isInWallet.value && hasMETFINFT.value
    })

    const isPrizeable = computed(() => {
        if (!props.mogulNFT) return false
        return props.mogulNFT.has_hidden_prize && hasMETFINFT.value && !props.mogulNFT.hidden_prize_claimed
    })


</script>

<style scoped>

</style>
